export const esMessages = {
  ra: {
    action: {
      add_filter: 'Añadir filtro',
        add: 'Añadir',
        back: 'Volver',
        bulk_actions: '1 elemento seleccionado |||| %{smart_count} elementos seleccionados',
        cancel: 'Cancelar',
        clear_input_value: 'Limpiar valor',
        clone: 'Clonar',
        create: 'Añadir',
        create_item: 'Añadir', /* %{item} */
        delete: 'Eliminar',
        edit: 'Modificar',
        export: 'Exportar',
        list: 'Listar',
        refresh: 'Refrescar',
        remove_filter: 'Eliminar este filtro',
        remove: 'Eliminar',
        save: 'Guardar',
        search: 'Buscar',
        show: 'Mostrar',
        sort: 'Ordenar',
        undo: 'Deshacer',
        confirm: 'Confirmar',
        unselect: 'Deseleccionar',
        expand: 'Expandir',
        close: 'Cerrar',
        open_menu: 'Abrir menú',
        close_menu: 'Cerrar menú',
    },
    boolean: {
      true: 'Si',
      false: 'No',
      null: ' ',
    },
    page: {
      create: 'Añadir', /* %{name} */
      dashboard: 'Inicio',
      edit: '%{name} #%{id}',
      error: 'Se ha producido un error',
      list: 'Lista de %{name}',
      loading: 'Cargando...',
      not_found: 'No se ha encontrado',
      show: '%{name} #%{id}',
      empty: 'Aún no hay %{name}.',
      invite: '¿Quieres añadir una?',
    },
    input: {
      file: {
        upload_several: 'Arrastra los archivos a subir o pulsa para seleccionar uno.',
        upload_single: 'Arrastra el archivo a subir o pulsa para seleccionarlo.',
      },
      image: {
        upload_several: 'Arrastra las imágenes para subirlas o haz clic para seleccionar una.',
        upload_single: 'Arrastra la imagen para subirla o haz clic para seleccionarla.',
      },
      references: {
        all_missing: 'No se han podido encontrar referencias.',
        many_missing: 'Al menos una de las referencias asociadas no parece estar disponible.',
        single_missing: 'La referencia asociada ya no está disponible.',
      },
      password: {
        toggle_visible: 'Ocultar la contraseña',
        toggle_hidden: 'Mostrar contraseña',
      },
    },
    message: {
      about: 'Sobre',
      are_you_sure: '¿Esta seguro?',
      bulk_delete_content: '¿Está seguro de borrar el elemento %{name}? |||| ¿Está seguro de borrar estos %{smart_count} elementos?',
      bulk_delete_title: 'Borrar %{name} |||| Borrar %{smart_count} elementos de %{name}',
      delete_content: '¿Está seguro de borrarlo',
      delete_title: 'Borrar registro' , /* %{name} #%{id}" */
      details: 'Detalles',
      error: 'Se ha producido un error en el cliente y su solicitud no se ha podido completar.',
      invalid_form: 'El formulario no es válido. Por favor, revisa los errores',
      loading: 'La página se está cargando, por favor espere un momento',
      no: 'No',
      not_found: 'O bien ha escrito una URL incorrecta, o bien ha navegado a un enlace erróneo.',
      yes: 'Sí',
      unsaved_changes: 'Algunos de los cambios no se han guardado. ¿Está seguro de que quiere ignorarlos',
    },
    navigation: {
      no_results: 'No hay registros',
      no_more_results: 'La página número %{page} está fuera de rango. Pruebe con la página anterior.',
      page_out_of_boundaries: 'Página número %{page} fuera de rango',
      page_out_from_end: 'No se puede ir más allá de la última página',
      page_out_from_begin: 'No se puede ir más allá de la página 1',
      page_range_info: '%{offsetBegin} - %{offsetEnd} de %{total}',
      page_rows_per_page: 'Filas por página:',
      next: 'Siguiente',
      prev: 'Anterior',
      skip_nav: 'Ve al contenido',
    },
    sort: {
      sort_by: 'Ordenar por %{field} %{order}',
      ASC: 'ascendente',
      DESC: 'descendente',
    },
    auth: {
      user_menu: 'Perfil',
      username: 'Usuario',
      password: 'Contraseña',
      sign_in: 'Iniciar sesion',
      sign_in_error: 'Ha fallado la autenticación, por favor intente de nuevo',
      logout: 'Cerrar sesion',
      auth_check_error: 'Inicie sesión para continuar',
      error: 'Usuario o contraseña incorrecta',
      auth_account_locked: 'La cuenta está bloqueada. Espera unos minutos y vuelve a intentarlo',
      auth_too_many_attempts: 'Se han realizado demasiados intentos de inicio de sesión. Por seguridad, la cuenta ha quedado bloqueada durante 5 minutos.',
      auth_invalid_credentials: 'Usuario o contraseña incorrecta'
    },
    notification: {
      updated: 'Registro actualizado |||| %{smart_count} registros actualizados',
      created: 'Registro creado',
      deleted: 'Registro eliminado |||| %{smart_count} registros eliminados',
      bad_item: 'Elemento incorrecto',
      logged_out: 'Su sesión ha finalizado, vuelva a iniciar sesión.',
      item_doesnt_exist: 'El elemento no existe',
      http_error: 'Error de comunicación con el servidor',
      data_provider_error: 'Error del dataProvider. Comprueba la consola para más detalles.',
      canceled: 'Acción cancelada',
      i18n_error: 'No se pueden cargar las traducciones para el idioma especificado',
    },
    validation: {
      required: 'Obligatorio',
      minLength: 'Longitud mínima de %{min} carácteres',
      maxLength: 'Máximo %{max} carácteres',
      minValue: 'El valor mínimo es %{min}',
      maxValue: 'El valor máximo es %{max}',
      number: 'Número inválido',
      email: 'Debe ser una dirección de correo electrónico válida',
      oneOf: 'Debe ser uno de estos valores: %{options}',
      regex: 'Debe cumplir el formato (expresión regular): %{pattern}', 
    }
  },


  common : {
    id : 'Id',
    name : 'Nombre',
    name_internal : 'Nombre interno',
    description : 'Descripción',
    email : 'Correo electrónico',
    image : 'Imagen',
    action : 'Acción',
    actions : 'Acciones',
    search : 'Buscar',
    actived : 'Activo',
    actived_female : 'Activa',
    edit : 'Editar',
    visible: 'Visible',
    priority: 'Orden',
    comment: 'Comentario',
    loading: 'Cargando...',
    data: 'Datos',
    type: 'Tipo',
    status: 'Estado',
    month_1: 'Ene',
    month_2: 'Feb',
    month_3: 'Mar',
    month_4: 'Abr',
    month_5: 'May',
    month_6: 'Jun',
    month_7: 'Jul',
    month_8: 'Ago',
    month_9: 'Sep',
    month_10: 'Oct',
    month_11: 'Nov',
    month_12: 'Dic',     
    months_1: 'Enero',
    months_2: 'Febrero',
    months_3: 'Marzo',
    months_4: 'Abril',
    months_5: 'Mayo',
    months_6: 'Junio',
    months_7: 'Julio',
    months_8: 'Agosto',
    months_9: 'Septiembre',
    months_10: 'Octubre',
    months_11: 'Noviembre',
    months_12: 'Diciembre',    
    year : 'Año',
    language: 'Idioma',
    language_0: 'Català',
    language_1: 'Castellà',
    notification : { 
      updated: 'Elemento actualizado',
      error: 'Se ha producido un error'
    },
    validation: {
      required: 'Obligatorio',
      minLength: 'Longitud mínima de %{min} carácteres',
      maxLength: 'Máximo %{max} carácteres',
      minValue: 'El valor mínimo és %{min}',
      maxValue: 'El valor máximo és %{max}',
      number: 'Número inválido',
      email: 'El formato del correo electrónico no es correcto',
      oneOf: 'Debe ser uno de estos valores: %{options}',
      regex: 'Debe cumplir con el formato (expresión regular): %{pattern',
      minMaxLengthAndCharacters: '%{min}-%{max} caracteres, con un número, letra, mayúscula, minúscula y carácter especial',
    },
    yes: 'Sí',
    no: 'No' ,
    labels: 'Etiquetas',
    day_week_1: 'lun',
    day_week_2: 'mar',
    day_week_3: 'mie',
    day_week_4: 'jue',
    day_week_5: 'vie',
    day_week_6: 'sab',
    day_week_7: 'dom',
    day_week_complete_1: 'Lunes',
    day_week_complete_2: 'Marte',
    day_week_complete_3: 'Miercoles',
    day_week_complete_4: 'Jueves',
    day_week_complete_5: 'Viernes',
    day_week_complete_6: 'Sabado',
    day_week_complete_7: 'Domingo',
    title: 'Título',
    link: 'Enlace',
    notFoundPage: 'La página no existe',
    goBack: 'Volver atrás',
    date_from: 'Desde ',
    date_to: ' hasta el ',
    time_to: 'A las ',
    download_ok: 'El archivo se ha generado correctamente. Consulta la carpeta de descargas del navegador',
    excel: 'Excel',
    pdf: 'PDF',    
  },
  main_menu : {
    home: 'Inicio',
    groups: 'Grupos',
    users: 'Contactos',
    recercat_users: 'Carnets',
    recercat_centers: 'Centros',
    docs_fac: 'Documentos',
    remittances: 'Recibos',
    classrooms: 'Espacios',
    users_groups : 'Asignación a grupo',
    associations: 'Clientes',
    modules: 'Módulos',
    modules_view: 'Más Apps...',
    real_state: 'Inmuebles',
    agreements: 'Convenios',
    insurances: 'Seguros',
    permissions: 'Permisos',
    subscriptions: 'Suscripciones',
    options: 'Opciones',
    contacts: 'Contactos FAC',
    events: 'Agenda',
    reports: 'Informes',
  },
  dashboard : {
    members: 'Evolución de socios',  
    members_federation: 'Evolución',  
    birthdays: 'Aniversarios',
    members_legend_female : 'Mujeres',
    members_legend_male : 'Hombres',
    members_legend_no_binary : 'No binarios',
    members_legend_no_informed : 'No informados',
    members_legend_companies : 'Entidades jurídicas',
    last_joins: 'mas recientes',    
    last_joins_pending: 'Solicitudes pendientes de validar',
    members_view: 'Ver todos',
    events_view: 'Ver todas',
    members_federation_view: 'Ver todas',
    members_view_requests: 'Ver todas',
    members_federation_view_requests: 'Ver todas',
    members_add: 'Añadir',
    people: 'personas',
    age_sufix: 'años',
    age_pyramid: 'Pirámide de edades',
    today: 'Actividades de hoy',
    next_events: 'Próximas actividades',
    statistics_total_members: 'Número de socios',
    statistics_avg_age: 'Media de edad', 
    statistics_gender: 'Por genero',    
    remittances: 'Recibos',
    remittances_legend_accepted: 'Aceptados',
    remittances_legend_returned: 'Impagados',
    remittances_add: 'Crear una remesa',
    subscription_active_modules: "Módulos activos",
    subscription_view_all_modules: "Añadir módulos",
    subscription: 'Datos de la suscripción',
    subscription_name: 'Plan de servicio',
    subscription_status: 'Estado',
    subscription_date_expiration: 'Fecha de expiración',
    subscription_users : 'contactos',        
    subscription_size : 'MB',
    subscription_change : 'Modificar',
    help: 'Soporte al usuario',
    help_manual_link: 'Manual de usuario',       
    help_description_sifac: 'Para cualquier consulta o sugerencia sobre el uso de la aplicación, envía un correo electrónico a soporte@sifac.cat o llama al teléfono 691957814 de lunes a viernes de 9h a 13h y de 16h a 19h.',
    help_description: 'Para cualquier consulta o sugerencia sobre el uso de la aplicación, envía un correo electrónico a soporte@sifac.cat. Os reponderemos lo antes posible.',                
    federation_members: 'Número',
    federation_total_people: 'Número de socios de entidades',
    federation_avg_age: 'Media de edad',
    federation_gender: 'Por género',
    federation_map: 'Delegaciones territoriales',        
    federation_associations: 'entidades',
    alert_subscription_admin: 'Algunas funciones no están operativas porque no se ha detectado ninguna suscripción del Gestión de entidades activa. Por favor, contacta con el equipo de apoyo.',
    facm_auditory : 'Actos en auditori',
    facm_projects : 'Proyectos',
    facm_num_events: 'Actos',
    facm_num_entities: 'Entidades',
    facm_num_assistants: 'Asistentes',
  },
  
  modules_view : {
    title: "Módulos disponibles",
    actived: "Activado",   
    to_desactive: "Desactivar",   
    to_active_button: "Activar",    
    change_modules_title: "Activar/desactivar módulos",
    to_activate: "Para modificar los módulos activos y/o el plan de servicio contratado, ponte en contacto por correo electrónico a admin@gestioentitats.cat. Os responderemos lo antes posible.",
    to_activate_sifac: "Para modificar los módulos activos y/o el plan de servicio contratado, ponte en contacto por correo electrónico a suport@sifac.cat o bien llama al teléfono 691957814 de lunes a viernes de 9h a 13h y de 16h a 19h.",    
  },
  
  reports: {
    title: 'Generar informe',  
    annuary: 'Anuario',        
    annuary_desc: 'Datos para realizar el anuario',
    annuary_item_id: 'Id',
    annuary_item_group: 'Ámbito',
    annuary_item_calc: 'Cálculo',
    annuary_item_name: 'Nombre',
    annuary_item_value: 'Cantidad',
    annuary_item_percent: '%',
    annuary_item_avg: 'Media',
    annuary_exporter_file_name: 'anuario',
    annuary_last_years: 'Generar el informe con datos de años anteriores si no existen datos del año seleccionado',
    responsabilities: 'Directivos de entidades',      
    responsabilities_responsability: 'Cargo',   
    responsabilities_date: 'Fecha',   
    responsabilities_desc: 'Listado de directivos de entidades',    
    responsabilities_exporter_file_name: 'directivos',
    responsabilities_list_entity_name: 'Entidad',
    responsabilities_list_entity_city: 'Población',
    generate: 'Generar',    
    no_results: 'No hay resultados'
  },
  associations : {
    subscriptions : 'Suscripciones',
    tab_data : 'Datos',
    tab_subscriptions : 'Suscripciones',
    tab_admin : 'Administradores',
    tab_statistics : 'Estadísticas',
    generate_data_demo : 'Generar datos de prueba',
        
    report_users: 'Usuarios',
    report_groups: 'Grupos',
    report_emails: 'Emails',
    report_emails_not_sent: 'No enviados (48h)',
    report_docs: 'Docs',
    report_mb: 'MB',
    report_remittances: 'Remesas',
    report_remittances_users: 'Recibos',
    report_last_action: 'Última acción',
    
    trial: 'Periodo de prueba',
    begin: 'Inicio suscripción',
    end: 'Final suscripción',
    
    email_sent_tooltip_button: 'Enviar notificación',
    email_sent_tooltip_sending: 'Se está enviando notificación...',
    email_sent_tooltip_done: 'Se ha enviado notificación',
    email_sent: 'Notificación',
    email_sent_title: 'Enviar notificación',
    email_sent_ask: 'Seguro que quieres enviarle la notificación?' ,  
    email_sent_ask_again: 'Ya se ha enviado anteriormente la notificación. ¿Seguro que quieres volver a enviarle?',        
    email_sent_ko: 'La notificación no se ha podido entregar al destinatario',
        
  },
  auth: {
    error_title: 'Se ha producido un error',
    forgot_password_title: '¿No recuerdas tú contraseña?',
    forgot_password_desc: 'Introduce tu correo electrónico y recibirás instrucciones para restablecerla.',
    forgot_password_ok: 'Revisa tu correo electrónico. <br/><br/> Si eres un usuario administrador te hemos mandado las instruciones para modificar la contraseña.',
    forgot_password_link: 'Ya recuerdo la contraseña',
    reset_password_title: 'Escribe tu nueva contraseña',
    reset_password_button: 'Modificar contraseña',
    reset_password_ok: 'Tú contraseña se ha actualizado correctamente',
    reset_password_ok_link: 'Ir a la página de autenticación',
    send_button: 'Enviar',
    error: 'Error'    
  },
  signup : {
    link_or: "o",
    link: "Regístrate",
    title: 'Regístrate para probar la aplicación',
    register: 'Registro de nuevo usuario',
    desc: 'Introduce el nombre de tu entidad y el correo electrónico, y te enviaremos el acceso para probar la aplicación <b>gratis durante 30 días</b>, sin necesidad de datos bancarios. Al finalizar este período, podrás decidir si deseas activar el plan de servicio.',
    ok: 'Hemos recibido tus datos correctamente. <br/><br/>En breve recibirás un correo electrónico con las credenciales de acceso a la aplicación.',
    ok_login: 'Acceder',
    name: 'Nombre de la entidad',
    send_button: 'Regístrate',
    
    form_lopd: 'Acepto que se traten mis datos para atender la solicitud de registro',
    form_lopd_validator: 'Debes aceptar el tratamiento de los datos',
    form_lopd_link: 'política de privacidad',
    
    form_terms: 'Acepto los ',
    form_terms_link: 'términos y condiciones',
    form_terms_validator: 'Debes aceptar los términos y condiciones',
    
    form_newsletter: 'Acepto que se traten mis datos para recibir ofertas y novedades',
    
    lopd: "Política de privacidad",
    terms: "Términos y condiciones",
    
  },
  
  subscriptions : {
    modules: 'Módulos',
    module: 'Módulo',
    options: 'Opciones',
    option: 'Opción',
    docs: 'Imágenes',
    date_init: 'Fecha de inicio',
    date_expiration: 'Fecha de expiración',
  },
  permissions : {
    user : 'Usuario',
    status : 'Estado',
    status0: 'Pendiente',
    status1 : 'Alta'
  },
  groups : {
    title: 'Grupos',
    group: 'Grupo',
    edit: 'Modificar',
    addChild: 'Añadir hijo',
    tab_users: 'Personas',
    tab_main: 'Grupo',
    tab_quotes: 'Cuotas',
    tab_docs: 'Documentos',
    tab_subgroups: 'Subgrupos',
    tab_responsabilities: 'Cargos',
    num_members: 'Miembros actuales',
    name: 'Nombre del grupo',
    card: 'Carnet digital',
    card_digital: 'Se pueden generar carnets digitales en PDF',
    card_digital_wallets: 'Se pueden generar carnets digitales en Google Wallet y Apple Wallet',
  },
  groups_cards : {    
    design_title: 'Diseño del carnet',
    type: 'Elemento',
    name: 'Títol',
    pos_x: 'M. Izquierda (%)',
    pos_y: 'M. Superior (%)',
    size_x: 'Ancho (%)',
    size_y: 'Alto (%)',
    num_lines: 'Núm. máximo de líneas',
    font_size: 'T. Letra (%)',
    font_color: 'Color',
    opacity: 'Opacidad (%)',
    rectangle_round: 'Esquinas redondeadas (%)',
    title: 'Nombre del campo',
    message: 'Valor del campo',
    status: 'Visible',
    type_0: 'Nombre de la entidad',
    type_0_default_title: 'Entidad',
    type_1: 'Logotipo de la entidad',
    type_1_default_title: '',
    type_2: 'Nombre + Apellido1 + Apellido2 (miembro)',
    type_2_default_title: 'Nombre',
    type_3: 'Nombre + Apellido1 (miembro)',
    type_3_default_title: 'Nombre',
    type_4: 'Apellido1 Apellido2, Nombre (miembro)',
    type_4_default_title: 'Nombre',
    type_5: 'Núm. de miembro (miembro)',
    type_5_default_title: 'Número',
    type_6: 'Fotografía (miembro)',
    type_6_default_title: '',
    type_7: 'DNI/NIF (miembro)',
    type_7_default_title: 'DNI/NIF',
    type_8: 'Fecha de expedición',
    type_8_default_title: 'Expedición',
    type_9: 'Fecha de alta',
    type_9_default_title: 'Fecha alta',
    type_10: 'Código QR',
    type_10_default_title: '',
    type_11: 'Nombre del grupo',
    type_11_default_title: 'Grupo/sección',
    type_12: 'Correo electrónico (miembro)',
    type_12_default_title: 'Correo electrónico',
    type_13: 'Nombre (miembro)',
    type_13_default_title: 'Nombre',
    type_14: 'Apellido1 (miembro)',
    type_14_default_title: '1r apellido',
    type_15: 'Apellido2 (miembro)',
    type_15_default_title: '2o apellido',
    type_16: 'Apellido1 + Apellido2 (miembro)',
    type_16_default_title: 'Apellidos',
    type_17: 'Nombre del centro',
    type_17_default_title: 'Nombre del centro',
    type_18: 'Localidad del centro',
    type_18_default_title: 'Población del centro',
    type_19: 'Dirección de la entidad',
    type_19_default_title: 'Dirección de la entidad',
    type_20: 'Localidad de la entidad',
    type_20_default_title: 'Población de la entidad',
    type_21: 'Teléfono de la entidad',
    type_21_default_title: 'Teléfono de la entidad',
    type_22: 'Marco semi-transparente',
    type_22_default_title: '',
    type_23: 'Color de fondo',
    type_23_default_title: '',
    type_24: 'Campo de texto',
    type_24_default_title: '',
    type_25: 'Color de títulos de los campos',
    type_25_default_title: '',
    type_26: 'Color de letra',
    type_26_default_title: '',
    position: 'Posición',
    position_0: 'Título cabecera frontal',
    position_1: 'Título cabecera posterior',
    position_2: 'Campo principal',
    position_3: 'Campo secundario',
    position_4: 'Campo auxiliar',
    position_5: 'Campo bajo QR',
    position_6: 'Campo parte posterior',
    alignment: 'Alineación',
    alignment_0: 'Izquierda',
    alignment_1: 'Centrado',
    alignment_2: 'Derecha',
    alignment_3: 'Justificado',
    uppercase: 'Mayúsculas',
    pdf_view: 'Descargar carnet',
    regenerate: 'Regenerar código QR',
    regenerate_title: 'Regenerar código QR',
    regenerate_text: '¿Seguro que deseas regenerar el código QR del carnet digital? Esta operación solo debe realizarse en caso de pérdida o robo del carnet. El carnet anterior dejará de ser válido. Esta operación no envía el carnet al contacto; deberá hacerse manualmente.',
    regenerated_ok: 'Código QR regenerado correctamente',
    pdf_card_filename: 'carnet.pdf',
    pdf_card_ok: 'El carnet se ha generado correctamente. Consulta la carpeta de descargas del navegador',
    pdf_card_download: 'Previsualización',
    send_dialog_button: 'Enviar carnets digitales',
    send_dialog_action: 'Acción a realizar',
    send_dialog_action_0: 'Enviar el carnet digital solo a los contactos a quienes no se ha enviado (%num_pending%)',
    send_dialog_action_1: 'Enviar el carnet digital a todos los contactos del grupo (%num_total%)',
    send_dialog_block_size: 'Límite de envío',
    send_dialog_regenerate: 'Regenerar el código QR. Si seleccionas esta opción, los contactos que ya tenían carnet digital tendrán uno nuevo y el anterior será inválido. Si no lo seleccionas, los usuarios que ya tenían carnet digital mantendrán su código QR, y el carnet anterior continuará siendo válido.',
    send_dialog_text2: 'Se enviará a los contactos que dispongan de correo electrónico. El envío se ejecutará en segundo plano y puede tardar unos minutos. Podrás consultar el estado del envío en la ficha de cada contacto o usando los filtros de la lista de contactos.',
    pdf_card_preview: 'Cargando la previsualización del carnet...',
    create: 'Añadir elemento',
    create_config: 'Añadir configuración',
    edit: 'Modificar elemento',
    edit_config: 'Modificar configuración',
    config: 'Configuración',
    background: 'Imagen de fondo',
    settings_pdf: "Carnet en PDF",
    settings_wallets_google: "Google Wallet",
    settings_wallets_apple: "Apple Wallet (fichero .pkpass)",
    background_google_wallet: "Imagen inferior del carnet",
    google_wallet_icon: "Icono",
    apple_wallet_icon: "Imagen de cabecera",
    google_wallet_background_color: "Color de fondo"
  },
  users : {
    tab_main: 'Datos básicos',
    tab_main_0: 'Datos personales',
    tab_main_1: 'Datos generales',
    tab_more: 'Más datos',
    tab_groups: 'Grupos',
    tab_responsabilities: 'Cargos',
    tab_responsabilities_fac: 'Cargos en la FAC',
    tab_quotes: 'Cuotas',
    tab_contacts: 'Contactos relacionados',
    tab_contacts_rel: 'Contactos relacionados',
    tab_economic_data: 'Presupuesto anual',
    tab_workers_0: 'Personal remunerado',
    tab_workers_1: 'Voluntarios',
    tab_docs: 'Documentos',
    tab_images: 'Imágenes',
    tab_family: 'Familia',
    tab_history: 'Historial',
    tab_social_nets: 'Redes sociales',
    user_type: 'Tipo',
    user_type_0: 'Física',
    user_type_1: 'Jurídica',
    group: 'Grupo',
    groups: 'Grupos',
    without_group: 'Sin grupo',
    first_name: 'Nombre',
    name_company: 'Nombre fiscal',
    last_name_1: 'Primer apellido',
    last_name_2: 'Segundo apellido',
    email: 'Correo electrónico',
    email_secondary: 'Correo electrónico secundario',
    email_secondary_list: 'Direcciones de correo electrónico adicionales',
    code: 'Número interno',
    address: 'Dirección',
    neighborhood: 'Barrio',
    zip: 'Código postal',
    city: 'Población',
    region: 'Comarca',
    country: 'País',
    lat: "Coord. Latitud",
    lng: "Coord. Longitud",
    phones: 'Teléfonos',
    phone: 'Teléfono fijo',
    mobile: 'Teléfono móvil',
    web: 'Página web',
    phone_emergency: 'Teléfono de emergencia',
    birthday: 'Fecha de nacimiento',
    foundation_date: 'Fecha de fundación',
    foundation_age: 'Antigüedad',
    job: 'Profesión',
    nif: 'NIF',
    nif_only: 'NIF',
    gender: 'Género',
    gender_1: 'Hombre',
    gender_2: 'Mujer',
    gender_3: 'No binario',
    comment: 'Observaciones',
    bank_data: 'Datos bancarios',
    bank_iban: 'IBAN',
    bank_swift: 'BIC/SWIFT',
    bank_payer: 'Pagador',
    no_users_title: 'Aún no hay personas.',
    no_users_ask: '¿Quieres añadir algunas?',
    person: 'Persona',
    payment_cash: 'Pago en efectivo',
    newsletter: 'Acepta recibir comunicaciones de la entidad',
    age: 'Edad',
    age_sufix: 'años',
    others: 'Otros',
    import: 'Importar',
    import_title: 'Importar personas para este grupo desde Excel',
    import_template: 'Descargar la plantilla Excel',
    add_to_group: 'Añadir a',
    add_member_comments: 'Dudas, comentarios o sugerencias',
    add_member_send: 'Enviar',
    validate: 'Validar',
    validate_title: 'Validar socio/a',
    validate_done: 'Se ha validado el socio/a',
    validate_send_email: 'Enviar correo electrónico de bienvenida ahora',
    welcome_email_sent: 'Correo de bienvenida',
    welcome_email_sent_title: 'Correo de bienvenida',
    welcome_email_sent_ask: '¿Seguro que quieres enviarle el correo de bienvenida predeterminado?',
    welcome_email_sent_ask_again: 'Ya se ha enviado previamente el correo de bienvenida. ¿Seguro que quieres enviarlo nuevamente?',
    welcome_email_sent_ok: '¡Envío realizado!',
    welcome_email_tooltip_button: 'Enviar correo de bienvenida',
    welcome_email_tooltip_sending: 'Se está enviando el correo de bienvenida...',
    welcome_email_tooltip_done: 'Se ha enviado el correo de bienvenida',
    welcome_email_sent_ko: 'No se ha podido entregar el correo de bienvenida al destinatario',
    
    group_left_email_sent: 'Correo de baja',
    group_left_email_sent_title: 'Correo de baja',
    group_left_email_sent_ask: '¿Seguro que quieres enviarle el correo de baja predeterminado?',
    group_left_email_sent_ask_again: 'Ya se ha enviado previamente el correo de baja. ¿Seguro que quieres enviarlo nuevamente?',
    group_left_email_sent_ok: '¡Envío realizado!',
    group_left_email_tooltip_button: 'Enviar correo de baja',
    group_left_email_tooltip_sending: 'Se está enviando el correo de baja...',
    group_left_email_tooltip_done: 'Se ha enviado el correo de baja',
    group_left_email_sent_ko: 'No se ha podido entregar el correo de baja al destinatario',
       
    digital_card_send: 'Carnet digital',
    digital_card_send_title: 'Carnet digital',
    digital_card_send_ask: '¿Seguro que quieres enviarle el carnet digital?',
    digital_card_send_ask_again: 'El carnet digital ya se envió. ¿Deseas enviarlo otra vez??',
    digital_card_send_ok: '¡Envío realizado!',
    digital_card_tooltip_button: 'Enviar carnet digital',
    digital_card_tooltip_sending: 'Se está enviando el carnet digital...',
    digital_card_tooltip_done: 'Se ha enviado el carnet digital',
    digital_card_send_ko: 'No se ha podido entregar el carnet digital al destinatario',
        
    exporter_file_name: 'contactos.xlsx',
    export: 'Exportar',
    alerts: 'Avisos',    
    
    delete_all: 'Eliminar',
    delete_all_confirm_title: 'Confirmación de eliminación de %num_registers% registros',
    delete_all_confirm_description: 'Se eliminarán todos sus datos, documentos, imágenes, reservas, recibos, histórico de grupos, ... y cualquier dato asociado que tengan.',
    delete_all_confirm_description_ask: '¿Estás seguro de que quieres eliminar los contactos seleccionados?',
    delete_all_ok: 'registros eliminados correctamente',
    delete_all_ko: 'Algunos registros no se han podido eliminar: ',
    
    group_changes: 'Cambios en grupos',
    group_changes_title: 'Cambios para los %num_registers% contacto/s seleccionado/s',
    group_changes_action: 'Acción a realizar',
    group_changes_action_1: 'Baja del grupo %group_name%',
    group_changes_action_2: 'Mover a grupo',
    group_changes_action_3: 'Asignar a grupo',
    group_changes_date_1: 'Fecha de baja',
    group_changes_date_2: 'Fecha de movimiento',
    group_changes_date_3: 'Fecha de alta',
    group_changes_confirm: 'Confirmar',
    group_changes_destination_group: 'Grupo destino',
    group_changes_ok_1: '%num_registers% contactos se han dado de baja correctamente',
    group_changes_ok_2: '%num_registers% contactos se han movido correctamente',
    group_changes_ok_3: '%num_registers% contactos se han asignado correctamente',
    group_changes_ko_1: '%num_registers% contactos no se han podido dar de baja',
    group_changes_ko_2: '%num_registers% contactos no se han podido mover',
    group_changes_ko_3: '%num_registers% contactos no se han podido asignar',
    
  },      
  users_filter: {
    title: 'Filtrar',
    filters: 'Filtros',
    field: 'Campo',
    operator: 'Operador',
    value: 'Valor',
    year: 'Año',
    
    filter_1: 'Nombre',
    filter_2: 'Primer apellido',
    filter_3: 'Segundo apellido',
    filter_4: 'Correo electrónico principal',
    filter_5: 'Tipo de contacto',
    filter_6: 'Dirección',
    filter_7: 'Barrio',
    filter_8: 'Código postal',
    filter_9: 'Población',
    filter_10: 'Comarca',
    filter_11: 'País',
    filter_12: 'NIF',
    filter_13: 'Página web',
    filter_14: 'Fecha de nacimiento',
    filter_15: 'Fecha de fundación',
    filter_16: 'Edad',
    filter_17: 'Antigüedad',
    filter_18: 'Correo electrónico adicional',
    filter_19: 'IBAN',
    filter_20: 'Cuota',
    filter_21: 'Último recibo',
    filter_22: 'Nombre completo',
    filter_23: 'Teléfono fijo',
    filter_24: 'Teléfono móvil',
    filter_25: 'Teléfono de emergencia',
    filter_26: 'Género',
    filter_27: 'Profesión',
    filter_28: 'Acepta comunicaciones',
    filter_29: 'BIC/SWIFT',
    filter_30: 'Pago en efectivo',
    filter_31: 'Fotografía',
    filter_32: 'Documentos',
    filter_33: 'Grupo: Fecha de alta en el grupo',
    filter_34: 'Grupo: Número de miembro',
    filter_35: 'Redes sociales',
    filter_36: 'Observaciones',
    filter_37: 'Avisos',
    filter_38: 'Grupo',
    filter_39: 'Centro',
    filter_40: 'Imágenes',
    filter_41: 'Correo electrónico secundario',
        
    filter_50: 'Grupo: Se ha enviado el correo de bienvenida',
    filter_51: 'Grupo: Se ha enviado el carnet digital',
        
    /* FEDERACIO */
    filter_80: 'Direcciones: Dirección',
    filter_81: 'Direcciones: Código postal',
    filter_82: 'Direcciones: Población',
    filter_83: 'Direcciones: Comarca',
    filter_84: 'Direcciones: Provincia',
    filter_85: 'Direcciones: País',
    filter_86: 'Direcciones: Dirección de envío',
    filter_87: 'Direcciones: Código postal de envío',
    filter_88: 'Direcciones: Población de envío',
    filter_89: 'Direcciones: Comarca de envío',
    filter_90: 'Direcciones: Provincia de envío',
    filter_91: 'Direcciones: País de envío',
        
    filter_99: 'Nombre popular',
    filter_100: 'Acrónimo',
    filter_101: 'Nombre del registro',
    filter_102: 'Número del registro',
    filter_103: 'BCIN',
    filter_104: 'BCIL',
    filter_105: 'Sistema de venta de entradas',
    filter_106: 'Forma parte del XTAC',
    filter_107: 'Entidad de interés cultural',
    filter_108: 'Entidad de utilidad pública',
    filter_109: 'Acceso al Programa Cat',
    filter_110: 'Último cambio de junta',
    filter_111: 'Próximo cambio de junta',
    filter_112: 'Delegación',
    filter_113: 'Acoge otras entidades',
    filter_114: 'Entidades que acoge',
    filter_121: 'Carnés de socios',
    filter_122: 'Logo de la FAC en el carnet',
    filter_123: 'Reglamento interno',
    filter_125: 'Placa de la FAC',
    filter_126: 'Licencia de actividades',
    filter_127: 'Titularidad del ateneo',
    filter_128: 'La propiedad es',
    filter_129: 'Cedido/Alquilado',
    filter_130: 'Expropiado actualmente',
    filter_131: 'Adaptados a la nueva LOPD',
    filter_132: 'Normativas vigentes de',
    filter_133: 'Memoria',
    filter_134: 'Forma parte de otras federaciones',
    filter_135: 'Federaciones de las que forma parte',
    filter_136: 'Carnet RCAT',
    filter_137: 'Entidad activa',
    filter_138: 'Entidad desaparecida',
    filter_139: 'Despojada por el franquismo',
    filter_140: 'Fecha de desaparición',
    filter_141: 'Personaje vinculado a resaltar',
        
    filter_200: 'Espacios',
    filter_201: 'Espacios: Total m2',
    filter_202: 'Espacios: m2',
    filter_203: 'Espacios: capacidad',

    filter_300: 'Presupuesto anual',
    filter_301: 'Presupuesto: total ingresos',
    filter_302: 'Presupuesto: total gastos',
    filter_303: 'Presupuesto: total inversión obras',
    filter_304: 'Presupuesto: resultado',
    filter_305: 'Presupuesto (ingresos): cuotas de socios',
    filter_306: 'Presupuesto (ingresos): servicios ofrecidos',
    filter_307: 'Presupuesto (ingresos): subvenciones',
    filter_308: 'Presupuesto (ingresos): arrendamientos',
    filter_309: 'Presupuesto (ingresos): venta de productos',
    filter_310: 'Presupuesto (ingresos): ayudas privadas',
    filter_311: 'Presupuesto (ingresos): otros',
    filter_312: 'Presupuesto (gastos): alquileres',
    filter_313: 'Presupuesto (gastos): personal',
    filter_314: 'Presupuesto (gastos): material',
    filter_315: 'Presupuesto (gastos): profesionales externos',
    filter_316: 'Presupuesto (gastos): cachés de grupos',
    filter_317: 'Presupuesto (gastos): suministros',
    filter_318: 'Presupuesto (gastos): mantenimiento',
    filter_319: 'Presupuesto (gastos): comunicación y publicidad',
    filter_320: 'Presupuesto (gastos): otros',
    filter_321: 'Presupuesto (inversión obras): edificio',
    filter_322: 'Presupuesto (inversión obras): teatro',
    filter_323: 'Presupuesto (inversión obras): bar',
    filter_324: 'Presupuesto (inversión obras): accesibilidad',
    filter_325: 'Presupuesto (inversión obras): seguridad',
    filter_326: 'Presupuesto (inversión obras): otros',
    filter_327: 'Presupuesto: Media de la cuota anual',

    filter_350: 'Datos demográficos',
    filter_351: 'Datos demográficos: Núm. de socios',
    filter_352: 'Datos demográficos: Media de edad',
    filter_353: 'Datos demográficos: Media de edad junta directiva',
    filter_354: 'Datos demográficos: Hombres (%)',
    filter_355: 'Datos demográficos: Mujeres (%)',
    filter_356: 'Datos demográficos: No binarios (%)',

    filter_400: 'Personal remunerado',
    filter_401: 'Personal remunerado: total personal',
    filter_402: 'Personal remunerado: administración',
    filter_403: 'Personal remunerado: técnico de actividades',
    filter_404: 'Personal remunerado: conserjería',
    filter_405: 'Personal remunerado: limpieza',
    filter_406: 'Personal remunerado: comunicación',
    filter_407: 'Personal remunerado: contabilidad',
    filter_408: 'Personal remunerado: mantenimiento',
    filter_409: 'Personal remunerado: gerencia',
    filter_410: 'Personal remunerado: otros',
        
    filter_450: 'Voluntarios',
    filter_451: 'Voluntarios: total',
    filter_452: 'Voluntarios: administración',
    filter_453: 'Voluntarios: técnico de actividades',
    filter_454: 'Voluntarios: conserjería',
    filter_455: 'Voluntarios: limpieza',
    filter_456: 'Voluntarios: comunicación',
    filter_457: 'Voluntarios: contabilidad',
    filter_458: 'Voluntarios: mantenimiento',
    filter_459: 'Voluntarios: gerencia',
    filter_460: 'Voluntarios: otros',

    filter_470: 'Formaciones en artes: total horas',
    filter_471: 'Formaciones en artes: estudiantes por formación',
    filter_472: 'Formaciones en artes: estudiantes por tipo de formación',
    filter_473: 'Formaciones en artes: profesorado remunerado por formación',
    filter_474: 'Formaciones en artes: profesorado remunerado por tipo de formación',
    filter_475: 'Formaciones en artes: profesorado voluntario por formación',
    filter_476: 'Formaciones en artes: profesorado voluntario por tipo de formación',

    filter_480: 'Formaciones: total horas',
    filter_481: 'Formaciones: estudiantes por formación',
    filter_482: 'Formaciones: estudiantes por tipo de formación',
    filter_483: 'Formaciones: profesorado remunerado por formación',
    filter_484: 'Formaciones: profesorado remunerado por tipo de formación',
    filter_485: 'Formaciones: profesorado voluntario por formación',
    filter_486: 'Formaciones: profesorado voluntario por tipo de formación',

    filter_490: 'Proyectos sociales',
    filter_491: 'Proyectos sociales por tipo',

    filter_500: 'Actividades',
    filter_501: 'Actividades: total actividades anuales',
    filter_502: 'Actividades: total asistentes anuales',
    filter_503: 'Actividades: cantidad',
    filter_504: 'Actividades: asistentes',

    filter_600: 'Espectáculos',
    filter_601: 'Espectáculos: total funciones',
    filter_602: 'Espectáculos: total asistentes',
    filter_603: 'Espectáculos: total presupuesto',
    filter_604: 'Espectáculos: funciones',
    filter_605: 'Espectáculos: asistentes',
    filter_606: 'Espectáculos: presupuesto',

    filter_700: 'Secciones',
    filter_701: 'Secciones: núm. de secciones',
    filter_702: 'Secciones: total participantes',
    filter_703: 'Secciones: participantes',

    filter_750: 'Ha respondido el formulario para el anuario',
    filter_751: 'Se ha enviado el formulario para el anuario',
    filter_752: 'Se ha enviado el recordatorio del formulario',

    filter_800: 'Forma parte de un censo',
    filter_801: 'Libros',
    filter_802: 'Envíos',

    filter_op_1: 'Contiene',
    filter_op_2: 'Es igual a',
    filter_op_3: 'Es diferente a',
    filter_op_4: 'Es mayor que',
    filter_op_5: 'Es mayor o igual que',
    filter_op_6: 'Es menor que',
    filter_op_7: 'Es menor o igual que',
    filter_op_8: 'No contiene',
    filter_op_9: 'En tiene',
    filter_op_10: 'No en tiene',
       
  },
    
  users_permissions: {
    email_sent: 'Correo de alta de administrador/a',
    email_sent_title: 'Correo de alta de administrador/a',
    email_sent_ask: '¿Seguro que quieres enviarle el correo de alta de administrador/a predefinido? Se generará una nueva contraseña.',
    email_sent_ask_again: 'Ya se ha enviado anteriormente el correo de alta de administrador/a. ¿Seguro que quieres volver a enviárselo? Se generará una nueva contraseña.',
    email_sent_ok: '¡Envío realizado!',
    email_tooltip_button: 'Enviar correo de alta de administrador/a',
    email_tooltip_sending: 'Se está enviando el correo de alta de administrador/a...',
    email_tooltip_done: 'Se ha enviado el correo de alta de administrador/a',
    email_sent_ko: 'El correo de alta de administrador no se ha podido entregar al destinatario',
    role: 'Permisos',
    role_SIFAC: 'Superadministrador',
    role_ADMIN: 'Administrador',
    role_ADMIN_CONTACTS: 'Administrador (lectura + edición de contactos)',
    role_ADMIN_READER: 'Administrador (lectura)',
  },
        
  users_federation : {
    tab: 'Datos de la Federación',
    tab_more: 'Más Federación',
    tab_managers: 'Junta directiva',
    tab_managers_1: 'Junta directiva actual',
    tab_managers_2: 'Miembros de juntas directivas anteriores',
    tab_managers_other: 'Miembro de juntas directivas de otros contactos',
    tab_classrooms: 'Espacios',
    name: 'Nombre popular',
    acronym: 'Acrónimo',
    reg_name: 'Nombre del registro',
    reg_num: 'Número del registro',
    bcin: 'BCIN',
    bcil: 'BCIL',
    tickets: 'Sistema de venta de entradas',
    xtac: 'Forma parte de la XTAC',
    culture_interest: 'Entidad de interés cultural',
    public_utility: 'Entidad de utilidad pública',
    soft_cat: 'Acceso al Programa Cat',
    rcat: 'Carnet RCAT',
    last_managers_change: 'Último cambio de junta',
    next_managers_change: 'Próximo cambio de junta',
    delegation: '¿Forma parte de una delegación?',
    host: '¿Acoge otras entidades?',
    host_entities: '¿Qué entidades?',
    more_federations: 'Forma parte de otras federaciones',
    federations: '¿Qué federaciones?',
    lopd: 'Adaptado al nuevo Reglamento Europeo de Protección de Datos (LOPD)',
    members_cards: 'Carnets de socios',
    logo_federation: '¿Tiene logo de la FAC?',
    internal_regulation: 'Reglamento interno',
    federation_plate: 'Placa de la FAC',
    ownership: 'Titularidad del ateneo',
    property: 'La propiedad es',
    rent: 'Cedido/Alquilado',
    expropied: 'Está expropiado actualmente',
    license: '¿Tiene licencia de actividades?',
    regulations: 'Normativas vigentes de',
    
    actived: 'Entidad activa',
    missing: 'Entidad desaparecida',
    robbed: 'Espoliada por el franquismo',
    missing_date: 'Fecha de desaparición',
    history_title: 'Historia',
    history: 'Historia Ateneo - La entidad',
    history_equipment: 'Historia de la entidad - El equipamiento, infraestructura',
    history_current: 'Historia Ateneo - Situación actual',
    
    census: 'Forma parte de un censo',
    mailing_lists: 'Envíos',
    activities_users: 'Etiquetas',
    books: 'Libros',
    
    edited_books_title: 'Publicaciones de la entidad',
    edited_books_books: 'Libros editados',
    edited_books_book_entity: 'Libro del ateneo',
    edited_books_newsletter: 'Boletín electrónico',
    edited_books_magazine: 'Revista',
    highlighted_dates: 'Fechas a resaltar',
    notable_individuals: 'Personajes vinculados a resaltar',
    links: 'Enlaces de interés',
    edited_books_memory: 'Memoria',
    edited_books_date: 'Fecha',
    edited_books_period: 'Periodicidad',
    event: 'Evento',
    
    recognitions: 'Reconocimientos',
    recognitions_date: 'Fecha',
    
    schedules: 'Horarios',
    schedules_day_week: 'Día',
    schedules_time_start: 'Hora de inicio',
    schedules_time_finish: 'Hora de fin',
    
    addresses: 'Direcciones',
    ship_address: 'Dirección de envío',
    province: 'Provincia',
    gmaps: 'Google Maps',
    addresses_create: 'Añadir dirección',
    addresses_edit: 'Modificar dirección',
    is_foundation_address: '¿Es la dirección de fundación de la entidad?',
    ship_to_same: 'La dirección de envío es la misma que la dirección principal',
    building: 'Edificio',
    regultations: 'Regulaciones',
    others: 'Otros',
    classrooms: 'Espacios',
    total_m2: 'Total m2',
        
  },
    
  users_groups :{
    date_join: 'Fecha de alta',
    date_left: 'Fecha de baja',
    responsability: 'Cargo',
    num_member: 'Número de miembro',
    last_num_inserted: 'Número de miembro más reciente: ',
    exporter_file_name: 'cargos',
    qr_code: 'Código QR',
    left: 'Dar de baja',
    left_send_email: 'Enviar correo electrónico predeterminado de baja a la persona ahora',
  },
  groups_quotes: {
    title: 'Periodicidades e importes',
    date_payment: 'Fecha de pago',
    month_payment: 'Inicio',
    period: 'Periodicidad',
    quantity: 'Importe',
    apply_to_all: 'Aplicar la cuota a todos los miembros automáticamente',
    is_donation: 'Es un donativo', 
    apply_to_all_list: 'Se aplica automáticamente',
    apply_to_all_donation: 'Aplicar a recibos',
    apply_to_all_donation_title: 'Establecer recibos existentes como donativos',
    apply_to_all_donation_desc: '¿Estás seguro que quieres establecer todos los recibos ya emitidos para esta cuota como donativos? Esta acción no se puede deshacer.',
    apply_to_all_donation_ok: 'Se han establecido todos los recibos de esta cuota como donativos',
    apply_to_all_donation_ko: 'No se ha podido realizar la acción',
  },
  users_groups_quotes : {
    group: 'Grupo',
    group_quote: 'Cuota',
    group_quote_period: 'Periodicidad',
    quantity: 'Importe',
    discount_ini: 'Inicio',
    discount_end: 'Fin',
    discount_percent: 'Dto (%)',
    discount_quantity: 'Dto (€)',
    manual_quantity: 'Importe manual (€)',
    comment: 'Comentario'
  },
  remittances: {
    tab_main: 'Remesa',
    tab_remittances_users: 'Recibos',
    concept: 'Concepto',
    quantity: 'Importe',
    date_creation: 'Fecha',
    count_users: 'Recibos',
    sum_quantity: 'Importe',
    sepa: 'Archivo SEPA',
    pdf: 'Recibos PDF',
    link_user: 'Persona',
    status: 'Estado',
    status0: 'Aceptado',
    status1: 'Impagado',
    statusFilterAll: 'Todos',
    statusFilter0: 'Aceptados',
    statusFilter1: 'Impagados',
    payer: 'Pagador',
    iban: 'IBAN',
    nif: 'NIF',
    status_form: 'Cambiar estado del recibo',
    status_new: 'Nuevo estado',
    check_reemit: 'Se podrá volver a generar el recibo en una nueva remesa',
    check_sent_return_notification: 'Enviar la notificación de impago al pulsar Guardar',
    comments: 'Observaciones',
    comments_form: 'Observaciones sobre el estado del recibo',
    default_name: 'Remesa_',
    groups_with_quotes: 'Generar automáticamente los recibos de',
    edit_state: 'Modificar',
    returns: 'Incorporar recibos impagados de otras remesas',
    refresh_payer: 'Actualizar datos',
    refresh_payer_confirm: 'Se han actualizado los datos de pago del usuario',
    no_remittances_users_title: 'remesas de recibos',
    no_remittances_users_ask: '¿Quieres añadir una?',
    payer_payment_cash: 'Es un pago en efectivo',
    payment_system: 'Forma de pago',
    bank: 'Recibo bancario',
    cash: 'Efectivo',
    pdf_filename: 'recibos',
    pdf_ok: 'El archivo se ha generado correctamente. Consulta la carpeta de descargas del navegador',
    ref: 'Ref.',
    resume_total_plural: 'recibos en la remesa',
    resume_total: 'recibos en la remesa',
    resume_bank_plural: 'recibos domiciliados',
    resume_bank: 'recibo domiciliado',
    resume_cash_plural: 'recibos en efectivo',
    resume_cash: 'recibo en efectivo',
    create: 'Añadir recibo',
    create_remittance: 'Añadir remesa',
    check_partial_quotes: 'Incluir cuotas proporcionales de los nuevos miembros. Por ejemplo, si hay un nuevo miembro en febrero y la siguiente cuota periódica no es hasta enero del siguiente año, se generará un recibo con la parte proporcional de los meses restantes.',
    name: 'Remesa',
    last_receips: 'Últimos recibos emitidos',
    send_returned_notification_title: 'Enviar notificación de impago',
    send_returned_notification_content: '¿Seguro que quieres enviar el correo de impago predefinido?',
    send_returned_notification_content_again: 'Ya se ha enviado anteriormente el correo. ¿Seguro que quieres volver a enviar el correo de impago predefinido?',
    send_returned_ko: 'El correo no se ha podido entregar al destinatario',
    send_returned_tooltip_button: 'Enviar notificación de impago',
    send_returned_tooltip_sending: 'Se está enviando la notificación de impago...',
    send_returned_tooltip_done: 'Se ha enviado la notificación de impago',
    exporter_file_name: 'recibos',
    exporter_header_returned_status: 'Notificación de impago enviada',
    is_donation: 'Es un donativo',    
  },   
  ibans : {
    create_iban: 'Añadir cuenta',
    public_id: 'Identificador',
    name: 'Nombre de la cuenta',
    ordering: 'Ordenando',
    iban: 'IBAN',
    swift: 'BIC/SWIFT',
    sufix: 'Sufijo',
    nif: 'NIF/CIF',
    returns_tax: 'Comisión de devolución (€)',
    bank_country_iso: 'País (ISO)',
    create: 'Añadir cuenta',
    edit: 'Editar cuenta',
    no_ibans: 'cuentas configuradas',
    name_create_remittance: 'Cuenta',
  },
  payment_systems: {
   main_menu_title: 'Pasarelas bancarias',
    create: 'Añadir pasarela',
    edit: 'Modificar pasarela',
    public_id: 'Identificador',
    plattform: 'Tipo',
    plattform_0: 'Redsys',
    plattform_1: 'Addon Payments',
    name: 'Nombre',
    merchant: 'Comercio',
    pswd: 'Contraseña',
    terminal: 'Terminal / Cuenta',
    url_ok: 'Url retorno OK',
    url_ko: 'Url retorno KO',
    url_async: 'Url de notificación',
    real: 'Entorno real',
    url: 'Url de conexión (modo REAL)',
    url_test: 'Url de conexión (modo TEST)',
    currency: 'Moneda',
    encrypt: 'Clave de encriptación',
    transaction_type: 'Tipo de transacción',
    exporter_file_name: 'pasarelas',
  }, 
  donations_projects: {
    id: 'Id proyecto',
    main_menu_title: 'Donativos',
    associations_id: 'Id entidad',
    associations_name: 'Entidad',
    reports_title: 'Proyectos para recibir donaciones de las entidades federadas',
    name: 'Proyecto',
    create: 'Añadir proyecto',
    description_short: 'Descripción corta para mostrar en la lista pública de proyectos',
    description: 'Descripción larga',
    video: 'Dirección de video Youtube',
    date_start: 'Inicio de la campaña',
    date_finish: 'Finalización',
    minimum_amount: 'Importe mínimo de la donación (€)',
    amount: 'Objetivo recaudación (€)',
    public_entity: 'Público en la web',
    public_fac: 'Público en la web de la FAC',
    public_fac_date: 'Fecha límite publicación',
    public_sum_donations: 'Publicar total aportaciones',
    payment_card: 'Tarjeta',
    payment_systems_id: 'Pasarela de pago',
    payment_systems_fac: 'Formas de pago de la FAC',
    payment_transfer: 'Transferencia bancaria',
    payment_transfer_ibans_id: 'A la cuenta bancaria',
    ibans_iban: 'Abono a la cuenta bancaria',
    legal_text_1: 'Texto legal',
    legal_text_2: 'Texto legal',
    legal_text_3: 'Párrafo de justificación en el certificado de donación',
    confirmation_text_1: 'Texto de confirmación',
    confirmation_text_2: 'Texto de confirmación',
    confirmation_email_1: 'Texto correo-e de confirmación',
    count_donations: 'Financiadores',
    sum_donations: 'Importe financiadores',
    sum_donations_percent: '% recaudado',
    remaining_days: 'Tiempo restante',
    minute: 'minuto',
    minutes: 'minutos',
    hour: 'hora',
    hours: 'horas',
    day: 'día',
    days: 'días',
    seconds: 'segundos',
    second: 'segundo',
    filter_only_current: 'Proyectos vigentes',
    only_pending: 'Pendientes de validación',
    images: 'Imágenes',
    legal_text: 'Textos legales',
    payment_systems: 'Forma de pago',
    public: 'Publicación',
    preview: 'Previsualización',
    view: 'Web',
    fac_validation_status: 'Validación FAC',
    fac_validation_status_0: 'Modo edición',
    fac_validation_status_1: 'Pendiente de validación',
    fac_validation_status_2: 'Validado',
    fac_validation_status_3: 'Rechazado',
    fac_validation_actions: 'Acciones',
    fac_validation_actions_1: 'Solicitar validación',
    edit: 'Modificar',
    validate: 'Validar',
    target_by_date: 'Objetivo en un intervalo de fechas',
    reports: 'Donativos: Proyectos de las entidades',
    reports_desc: 'Validar los proyectos de las entidades federadas, consultar mecenas y añadir donativos en efectivo o transferencia bancaria.',
    reports_transfer_validations: 'Donativos: Validación de transferencias bancarias',
    reports_transfer_validations_desc: 'Validar los donativos realizados mediante transferencia bancaria.',
    reports_payments: 'Donativos: Pagos a las entidades',
    reports_payments_desc: 'Consulta de los importes a transferir a las entidades',
    reports_certificates: 'Donativos: Certificados de donación',
    reports_certificates_desc: 'Generar los certificados de donación',
    validate_request: 'Solicitar validación',
    validate_request_button: 'Solicitar',
    validate_request_desc: 'Solicitar que la FAC revise y valide el proyecto para publicarse y comenzar a recibir donativos. A partir de ese momento no podrá modificar el proyecto',
    exporter_file_name: 'proyectos',
    view_web: 'Ver en la web',
    friendly_url: 'URL pública',
    city: 'Localidad',
    phone: 'Teléfono de contacto',
    email: 'Correo-e de contacto',
    friendly_url: 'Dirección pública',
    comments_actived: 'Comentarios activos',
    contact_data: 'Datos de contacto',
  }, 
  utils : {
    copy_clipboard_success: 'Se ha copiado al portapapeles',
    copy_clipboard_error: 'No se ha podido copiar al portapapeles',
  },
  public_donations_projects: {
   not_actived: 'La entidad no tiene proyectos de donaciones activos',
    for: 'Por',
    live: '¡En marcha!',
    early: 'Comenzará pronto',
    finished: '¡Finalizado!',
    count_donations: 'Financiadores',
    goal: 'Objetivo',
    view_all_projects: 'Todos los proyectos',
    remain: 'Quedan',
    remain_1: 'Queda',
    win: 'Logrado',
    more_info: '+ Info',
    contribute: 'Aportar',
    association_not_found: 'La entidad no se ha encontrado o ha ocurrido un error',
    not_found: 'El proyecto no se ha encontrado o ha ocurrido un error',
    view_web: 'Agenda web',
    go_back: 'Volver',
    search: 'Buscar',
    filter_date_after: 'Comienza después de',
    filter_date_before: 'Comienza antes de',
    filter_organizer_all: 'Todas las entidades',
    comments: 'Comentarios de financiadores',
    ago: 'hace',
    close: 'De acuerdo',
    return: 'Volver',
    preview: 'Esta página es una previsualización del proyecto'
  },
  donations_users: {
    main_menu_title: 'Financiadores',
    create: 'Agregar donativo',
    create_to: 'Hacer aportación a ',
    edit: 'Modificar',
    cancel: 'Cancelar',
    pay: 'Pagar',
    donations_projects_id: 'Id proyecto',
    donations_projects_name: 'Nombre del proyecto',
    associations_id: 'Id entidad',
    associations_name: 'Entidad',
    order_id: 'Referencia',
    date_time: 'Fecha de donación',
    user_type: 'Persona',
    first_name: 'Nombre',
    last_name_1: 'Apellido 1',
    last_name_2: 'Apellido 2',
    email: 'Correo electrónico',
    nif: 'DNI/NIF',
    address: 'Dirección',
    zip: 'Código postal',
    city: 'Ciudad',
    country: 'País',
    phone: 'Teléfono',
    payment_method: 'Método de pago',
    payment_method_0: 'Transferencia',
    payment_method_1: 'Tarjeta',
    payment_method_2: 'Efectivo',
    amount: 'Importe',
    status: 'Estado',
    status_0: 'Pendiente',
    status_1: 'Confirmado',
    status_2: 'Rechazado',
    date: 'Fecha de ingreso',
    send_confirmation: 'Enviar correo de confirmación al donante',
    transaction_id: 'Código de transacción',
    comment: 'Comentario',
    certificate: 'Certificado de donación',
    send_status: 'Certificado enviado',
    send_date_time: 'Fecha de envío',
    confirmation_status: 'Confirmación enviada al donante',
    confirmation_date_time: 'Fecha de envío',
    only_accepted: 'Solo pagos confirmados',
    only_pending: 'Solo pagos pendientes',
    only_certificate_not_sent: 'Solo certificados no enviados',
    pdf_download: 'Descargar',
    validate: 'Confirmar',
    validate_transfer: 'Confirmar transferencia',
    pdf_certificate_filename: 'certificado_donacion.pdf',
    pdf_certificate_ok: 'El certificado de donación se ha generado con éxito. Consulte la carpeta de descargas del navegador.',
    pdf_certificate_ko: 'El certificado de donación no se ha podido generar.',
    actions: 'Acciones',
    email_sent: 'Certificado de donación',
    email_sent_title: 'Certificado de donación',
    email_sent_ask: '¿Seguro que deseas enviar el certificado de donación?',
    email_sent_ask_all: '¿Seguro que deseas enviar el certificado de donación predefinido a todas las personas a quienes aún no se ha enviado?',
    email_sent_ask_again: 'El certificado de donación ya se ha enviado anteriormente. ¿Seguro que deseas enviarlo de nuevo?',
    email_send_all_button: 'Certificados de donación',
    email_send_all_button_title: 'Enviar certificados de donación',
    email_sent_ko: 'No se pudo entregar el certificado de donación al destinatario',
    email_sent_tooltip_button: 'Enviar certificado de donación',
    email_sent_tooltip_sending: 'Se está enviando el certificado de donación...',
    email_sent_tooltip_done: 'El certificado de donación se ha enviado',
    email_sent_confirmation: 'Confirmación de la donación',
    email_sent_confirmation_title: 'Confirmación de la donación',
    email_sent_confirmation_ask: '¿Seguro que deseas enviar la confirmación de la donación?',
    email_sent_confirmation_ask_again: 'La confirmación de la donación ya se ha enviado anteriormente. ¿Seguro que deseas enviarla de nuevo?',
    email_sent_confirmation_ko: 'No se pudo entregar la confirmación de la donación al destinatario',
    email_sent_confirmation_tooltip_button: 'Enviar confirmación de la donación',
    email_sent_confirmation_tooltip_sending: 'Se está enviando la confirmación de la donación...',
    email_sent_confirmation_tooltip_done: 'Se ha enviado la confirmación de la donación',
    exporter_file_name: 'financiadores',
    want_certificate: '¿Quiere certificado de donación?',
    copy: 'Copiar',
    copy_title: 'Copiar transferencia',
    donations_users_rel: 'Donativo relacionado'
  }, 
  donations_certificates : {
      title: 'Certificados de donación',
      generate: 'Ver',
      status : 'Estado envío',
      date_time : 'Fecha envío',
      exporter_file_name: 'certificados',
  },
  donations_payments : {
      title: 'Pagos a las entidades',
      date_from: 'Desde',
      date_to: 'Hasta',
      generate: 'Ver',
      reports_title: 'Pago de las donaciones a las entidades entre el %date_from% y %date_to%',
      exporter_file_name: 'pagos'
  },
  postal_labels : {
    create: 'Añadir formato',
    edit: 'Editar formato',
    name: 'Nombre',
    colxrows: 'Columnas x filas',
    margins_cell: 'Márgenes de la etiqueta (cm)',
    margins_page: 'Márgenes de la página (cm)',
    num_columns: 'Número de columnas',
    num_rows: 'Número de filas',
    template: 'Plantilla',
    available_fields: 'Campos disponibles:',
    fields: '%nombre% | %apellido1% | %apellido2% | %direccion% | %cp% | %poblacion% | %comarca% | %provincia% | %pais%',
    font_size: 'Tamaño de letra (puntos)',
    line_separator: 'Separación entre líneas (puntos)',
    margin_cell_left: 'Izquierdo',
    margin_cell_top: 'Superior',
    margin_cell_right: 'Derecho',
    margin_cell_bottom: 'Inferior',
    margin_page_left: 'Izquierdo',
    margin_page_top: 'Superior',
    margin_page_right: 'Derecho',
    margin_page_bottom: 'Inferior',
    last_row: 'Rellenar la última fila de cada página',
    priority: 'Orden',
    generate_button_title: 'Etiquetas',
    generate_modal_title: 'Generar etiquetas postales',
    generate_modal_desc: '¿Quieres generar un documento PDF (DIN A4) con las etiquetas de las direcciones postales de los contactos?',
    generate_modal_button: 'Generar',
    generate_format: 'Formato de la página (columnas x filas)',
    generate_pdf_filename: 'etiquetas.pdf',
    generate_ok_message: 'Las etiquetas se han generado con éxito. Consulta la carpeta de descargas del navegador.',
    add_formats: 'Añadir formatos',
  },
  classrooms: {
    no_classrooms_title: 'Aún no hay espacios',
    no_classrooms_ask: '¿Quieres añadir uno?',
    create: 'Añadir espacio',
    seats: 'Capacidad (personas)',
    type: 'Tipo de espacio',
    type0: 'Cafetería / Restaurante',
    type1: 'Teatro',
    type2: 'Sala de exposiciones',
    type3: 'Archivos',
    type4: 'Biblioteca',
    type5: 'Instalaciones deportivas',
    type6: 'Salas polivalentes',
    type7: 'Salas de cine',
    type8: 'Auditorio',
    type9: 'Jardín/Patio exterior',
    type10: 'Otros',
    m2: 'Tamaño (m2)',
    is_public: 'El espacio es visible en la página web',
    is_requestable: 'Se puede solicitar el uso de este espacio a través de la página web',
    is_for_events: 'Se pueden programar eventos en este espacio',
    data: 'Datos',
    photos: 'Fotografías',
    docs: 'Ficha técnica',
    color: 'Color en el calendario',
    overlapable: 'Se permite la superposición de eventos en este espacio',
    link_back: 'Volver',
    accessible: 'Es accesible',
    cession: 'En cesión',
    rent: 'Alquilado',
    rentable: 'Disponible para alquilar',
    lift: 'Tiene ascensor',
    visibility: 'Visibilidad',
    events: 'Eventos',
    outdoor: 'Es exterior',
  },
  real_state: {
    create: 'Añadir inmueble',
    address: "Dirección",
    city: "Población",
    use: "Uso",
    use_0: "Otros",
    use_1: "En alquiler",
    use_2: "En cesión",
    use_others: 'Otros usos',
    identifier: "Identificador",
    acquisition_date: 'Fecha de adquisición',
    sale_date: 'Fecha de venta',
    amount: 'Valor contable',
    guaranteed_value: 'Valor asegurado',
    cadastral_reference: 'Referencia catastral',
    comments: 'Observaciones',
    docs: 'Documentos',
    description: 'Descripción',
    insurance: 'Seguro',
    insurances: 'Seguros',
    docs_deed: 'Escrituras de propiedad, registro mercantil...',
    docs_map: 'Planos',
    activities: 'Actividad',
    activities_in_real_state: 'Actividades en inmuebles',
    payments: 'Gastos',
    agreements: "Convenios",
    filter_only_current: "Sólo actuales",
    exporter_file_name: 'inmuebles',
  },
  real_state_activities : {      
    create: 'Añadir actividad',
    name: 'Nombre de la actividad',
    user: 'Contacto',
    type: 'Tipo de relación',
    type_0: 'Otros',
    type_1: 'Convenio',
    type_2: 'Contrato',
    type_other: 'Tipo de relación',
    agreements_id: 'Convenio',
    date_start: "Fecha inicio",
    date_finish: "Fecha fin",
    date_alert: "Fecha preaviso",
    insurance_who: 'Responsable seguro',
    insurance_who_0: 'La persona',
    insurance_who_1: 'Nosotros',
    relations: 'Relación con la comunidad de propietarios',
    comments: 'Observaciones',
    docs: 'Documentos',
    docs_contracts: 'Contratos',
    docs_insurances: 'Seguros',
    real_state_id: 'Id inmueble',
    real_state: 'Nombre inmueble',
    exporter_file_name: 'actividad_inmuebles',
  },
  real_state_payments : {
    create: 'Añadir gasto',
    type: 'Tipo',
    type_0: 'Otros',
    type_1: 'Luz',
    type_2: 'Agua',
    type_3: 'Gas',
    type_4: 'Teléfono',
    type_5: 'Seguro',
    type_6: 'Obras',
    type_7: 'Mantenimiento',
    type_8: 'Reparaciones',
    type_9: 'Transformaciones',
    type_other: 'Tipo',
    date_payment: "Fecha pago",
    amount: 'Importe',
    payer: 'Pagador',
    payer_0: 'Otros',
    payer_1: 'Nosotros',
    payer_2: 'Comunidad',
    payer_3: 'Inquilino',
    payer_other: 'Pagador',
    financing: 'Financiación',
    financing_type: 'Tipo de financiación',
    docs: 'Documentos',
    docs_billing: 'Facturas',
    comments: 'Observaciones',
    exporter_file_name: 'pagos',
  },
  agreements: {
    create: 'Añadir convenio',
    users: "Personas",
    date_start: "Fecha inicio",
    date_finish: "Fecha fin",
    date_alert: "Fecha aviso",
    date_renewal: "Fecha renovación",
    renewal_duration: "Duración renovación",
    date_renewal_quote: "Renovación cuota",
    comments: 'Observaciones',
    users: "Contactos",
    real_state: "Inmuebles",
    docs: 'Documentos',
    filter_only_current: "Solo vigentes",
    exporter_file_name: 'convenios',
  },
  classrooms_activities: {
     create: 'Añadir actividad',
  },
  classrooms_payments: {
     create: 'Añadir pago',
  },
  insurances: {
    create: 'Añadir seguro',
    real_state: 'Inmuebles',
    date_start: 'Fecha inicio',
    date_finish: 'Fecha final',
    date_alert: 'Fecha alerta',
    date_renewal: 'Fecha renovación',
    renewal_duration: 'Duración renovación',
    comments: 'Observaciones',
    docs: 'Documentos',
    filter_only_current: "Sólo vigentes",
    exporter_file_name: 'seguros',
  },
  alerts: {      
    create: 'Añadir alerta',      
    type: 'Tipo',
    type_1: 'Genérica puntual',
    type_2: 'Periódica anual',
    type_3: 'Periódica mensual',
    type_4: 'Preaviso actividad inmueble',
    type_5: 'Preaviso convenio',
    type_6: 'Renovación convenio',
    type_7: 'Actualización cuota convenio',
    type_8: 'Preaviso seguro',
    type_9: 'Evento',
    message: 'Mensaje a mostrar',
    date_alert: 'Fecha puntual',
    date_day: 'Día del mes',
    date_month: 'Mes',
    actions: 'Acciones',
    action_send_email: 'Envío de correo electrónico a los administradores',
    action_popup: 'Mostrar mensaje en la ventana de alertas de los administradores',
    actived: 'Activa',
    delete_all: 'Borrar todas las alertas',
    no_results: 'No hay alertas',
    filter_only_current: 'Sólo activas',      
    postpone: 'Posponer alerta',
    date_apply: 'Nueva fecha',
    postpone_ok: 'Se ha pospuesto la alerta',
    delete: 'Borrar',
    delete_ok: 'Se ha borrado la alerta',
    delete_all_ok: 'Se han borrado todas las alertas',
  },  
  docs: {
    docs: 'Documentos',
    create: 'Añadir',
    edit: 'Cambiar el nombre',
    actions: 'Acciones',
    download: 'Descargar',
    size: 'Tamaño',
    fieldExcel: 'Documento .xlsx (máx. %size%MB)',
    fieldDoc: 'Documento (máx. %size%MB)',
    fieldImg: 'Imagen (máx. %size%MB)',
    change: 'Cambiar',
    webcam: 'Foto desde webcam',
    webcam_capture: 'Capturar',
    webcam_error: 'No se ha detectado la webcam',
    delete_photo_title: 'Borrar fotografía',
    delete_photo_ask: '¿Estás seguro de que quieres borrar la fotografía?',
    imported: 'Importación finalizada correctamente. Se han importado %num% registros',
    imported_none: 'No hay registros para importar',
    copy_link: 'Dirección pública',
  },
  events: {
    create: 'Añadir evento',
    edit: 'Modificar',
    name: 'Nombre del evento',
    description: 'Descripción',
    start: 'Fecha inicio',
    end: 'Fecha fin',
    classroom: 'Espacio',
    activity: 'Actividad',
    is_public: 'Publicarlo en la web de la entidad',
    is_public_filter: 'Publicados en la web',
    show_fac: 'Publicarlo en la web de la FAC',
    show_fac_filter: 'Publicados en FAC',
    repetitive: 'Generar repeticiones',
    repetitive_limit: 'Finaliza el día',
    repetitive_mode_edition_title_save: 'Editar el evento periódico',
    repetitive_mode_edition_title_delete: 'Borrar el evento periódico',
    repetitive_mode_edition_only_one: 'Este evento',
    repetitive_mode_edition_only_future: 'Este evento y los siguientes',
    repetitive_mode_edition_all: 'Todos los eventos',
    repetitive_mode_edition_save: 'Guardar',
    repetitive_mode_edition_delete: 'Borrar',
    repetitive_mode_edition_delete_confirmation: '¿Estás seguro de que quieres borrar los eventos?',
    repetitive_mode_edition_delete_confirmation_2: '¿Estás seguro/a de que quieres borrar los eventos?',
    repetitive_mode_edition_cancel: 'Cancelar',
    overlapable_title: 'Superposición de eventos',
    overlapable_ask: '¿Quieres permitir la celebración de varios eventos en el mismo espacio y tiempo?',
    data: 'Datos',
    images: 'Imágenes',
    images_desc: '* Puedes marcar las imágenes que se enviarán en el correo electrónico de invitación. Ten en cuenta reducir al máximo el tamaño de los archivos.',
    docs: 'Documentos',
    docs_desc: '* Puedes marcar los documentos que se enviarán en el correo electrónico de invitación. Ten en cuenta reducir al máximo el tamaño de los archivos.',
    docs_mark: 'Adjunto al correo de invitación (*)',
    bookings: 'Personas',
    exporter_file_name: 'actividades',
    exporter_header_id: 'Identificador interno',
    exporter_header_title: 'Evento',
    exporter_header_date_start: 'Fecha inicial',
    exporter_header_date_finish: 'Fecha final',
    exporter_header_classrooms_name: 'Espacio',
    exporter_header_activities_name: 'Actividad',
    exporter_header_is_public: 'Es público',
    export_title: 'Exportar actividades',
    export_form_text: 'Fecha de inicio',
    export_form_from: 'Desde',
    export_form_to: 'Hasta',
    pdf_filename: 'eventos',
    organizer: 'Organizador',
    address: 'Dirección del evento',
    city: 'Población',
    alert_days: 'Aviso a los administradores',
    alert_days_none: 'Sin aviso',
    alert_days_0: 'El mismo día',
    alert_days_1: '1 día antes',
    alert_days_2: '2 días antes',
    alert_days_3: '3 días antes',
    alert_days_4: '4 días antes',
    alert_days_5: '5 días antes',
    alert_days_6: '6 días antes',
    alert_days_7: '7 días antes',
    alert_days_8: '8 días antes',
    alert_days_9: '9 días antes',
    alert_days_10: '10 días antes',
    alert_days_11: '11 días antes',
    alert_days_12: '12 días antes',
    alert_days_13: '13 días antes',
    alert_days_14: '14 días antes',
    tickets_url: 'URL venta de entradas',
    max_assistants: 'Número de asistentes',
    is_internal: 'És un evento interno',
    is_internal_filter: 'Evento interno',
  },
  public_events: {
    not_actived: 'La entidad no tiene la agenda activa',
    association_not_found: 'La entidad no se ha encontrado o ha habido un error',
    event_not_found: 'La actividad no se ha encontrado o ha habido un error',
    view_web: 'Agenda web',
    go_back: 'Volver atrás',
    search: 'Buscar',
    filter_date_after: 'Comienza después de',
    filter_date_before: 'Comienza antes de',
    filter_activity: 'Actividad',
    filter_organizer: 'Organizador',
    filter_province: 'Provincia',
    filter_region: 'Comarca',
    filter_city: 'Población',
    filter_organizer_all: 'Todas las entidades',
    filter_activity_all: 'Todas las actividades',
    filter_province_all: 'Todas las provincias',
    filter_region_all: 'Todas las comarcas',
    filter_city_all: 'Todas las poblaciones',
    more_info: '+ Info',
    tickets: 'Entradas',
  },
  recercat_centers:
    {
    public_id: 'Código',
    name: 'Nombre',
    city: 'Población',
    email: 'Correo electrónico',
    actived: 'Activo',
    create: 'Añadir centro',
    no_centers_title: 'centros',
    no_centers_ask: '¿Quieres añadir uno?',
    column_center: 'Centro',      
    column_city: 'Población (sede de la entidad)',      
    actions: 'Acciones',
    actions_revoke: 'Revocar',
    actions_validate: 'Validar',
    actions_deny: 'Denegar',
    actions_title0: 'Revocar carnet',    
    actions_title1: 'Validar solicitud de carnet',    
    actions_title2: 'Denegar solicitud de carnet',
    actions_title3: 'Validar solicitud de carnet',
    actions_title4: 'Revocar carnet',
    actions_title5: 'Denegar solicitud de carnet',
    actions_title6: 'Validar solicitud de carnet',        
    action_button0: 'Revocar',
    action_button1: 'Validar',
    action_button2: 'Denegar',
    action_button3: 'Validar',
    action_button4: 'Revocar',
    action_button5: 'Denegar',
    action_button6: 'Validar',        
    validate_num_member: 'Número de carnet',
    validate_send_email: 'Enviar el carnet digital a la persona',
    revoke_send_email: 'Enviar mensaje de confirmación a la persona',
    revoke_title: 'Revocar carnet',    
    revoke_ask: '¿Estás seguro de que quieres revocar el carnet? Se borrarán los datos personales y no se podrán recuperar.',    
    deny_ask: '¿Estás seguro de que quieres denegar la solicitud de carnet? Se borrarán los datos personales y no se podrán recuperar.',    
    revoke_comment: 'Motivo',    
    revoke_button: 'Revocar',        
    resume_send: 'Envío de resumen',
    resume_send_title: 'Envío de resumen',
    resume_send_ask: '¿Estás seguro de que quieres enviar un resumen de los carnets al centro?',  
    resume_send_ask_again: 'Ya se ha enviado anteriormente un resumen de los carnets al centro. ¿Estás seguro de que quieres volver a enviarlo?',
    resume_send_ok: '¡Envío realizado!',
    resume_send_tooltip_button: 'Enviar resumen de carnets',
    resume_send_send_tooltip_sending: 'Se está enviando el resumen de carnets...',
    resume_send_tooltip_done: 'Se ha enviado el resumen de carnets',
    resume_send_ko: 'No se ha podido entregar el resumen de carnets al destinatario',
        
    dashboard_resume_title: 'Estado de carnets',
    dashboard_resume_num_members: 'Carnets activos',
    dashboard_resume_num_centers: 'Total de centros activos',
    dashboard_graphic_title: 'Carnets validados',
    dashboard_graphic_cards: 'Carnets',

    validation_email_code_0: 'Su solicitud para obtener el Carnet digital Recercat ha sido aceptada correctamente.<br/><br />Pronto le enviaremos el Carnet digital Recercat a su dirección de correo electrónico.<br /><br />Revise la carpeta de correo no deseado de su bandeja de entrada.<br /><br />',
    validation_email_code_1: 'El código de la dirección no es correcto',
    validation_email_code_2: 'La solicitud no se ha encontrado o ya no está activa',
    validation_email_code_3: 'Su solicitud ya está en curso.',
    validation_email_code_4: 'La entidad de la dirección no es correcta.',
    validation_email_code_5: 'El formulario de solicitud de carnets Recercat está desactivado.',
    validation_email_code_6: 'El enlace ya no es válido. Por favor, vuelva a realizar la solicitud.',
    validation_email_error: 'Se ha producido un error al intentar validar la solicitud',

    form_title: 'Formulario de solicitud del Carnet digital Recercat',
    form_title_send_email: 'Formulario de solicitud del Carnet digital Recercat',
    form_title_validate_ok: 'Confirmación de solicitud del carnet Recercat',
    form_title_validate_ko: 'Solicitud del carnet Recercat',
    required_fields: '* Todos los campos son necesarios',

    resume_title: 'Estado de los carnets Recercat',
    resume_pending_center: 'Pendientes de validación centro',
    resume_pending_irmu: 'Pendientes de validación por el IRMU',
    resume_validated: 'Validados por el IRMU',
    resume_error: 'No se han podido recuperar los datos, la dirección no es válida.',
    resume_actions_accept: 'Aceptar',
    resume_actions_deny: 'Denegar',
    resume_actions_revoke: 'Revocar',
    resume_actions_logout: 'Cerrar sesión',
        
    resume_dialog_title_3: 'Aceptar solicitud de carnet',
    resume_dialog_desc_3: 'La solicitud continuará su proceso y pasará al estado Pendiente de Validación IRMU.',
    resume_dialog_button_3: 'Aceptar',
    resume_dialog_title_5: 'Denegar solicitud',
    resume_dialog_desc_5: '¿Estás seguro de que deseas denegar la solicitud de carnet? La persona recibirá un correo electrónico predefinido informándole de la denegación y se borrarán todos sus datos.',
    resume_dialog_button_5: 'Denegar',
    resume_dialog_title_4: 'Revocar carnet',
    resume_dialog_desc_4: '¿Estás seguro de que deseas revocar el carnet? La persona recibirá un correo electrónico predefinido informándole de la baja y se borrarán todos sus datos.',
    resume_dialog_button_4: 'Revocar',
    resume_dialog_title_8: 'Cerrar sesión',
    resume_dialog_desc_8: '¿Estás seguro de que deseas cerrar la sesión? El enlace dejará de ser válido y solo podrás volver a acceder a esta página si recibes un nuevo correo de estado de carnets Recercat.',
    resume_dialog_button_8: 'Cerrar sesión',

    delete_center_title: '¿Estás seguro de que deseas borrar el centro?',
    delete_center_ask: 'Se revocarán todos sus carnets actuales, se denegarán las solicitudes de carnet pendientes y se desvinculará el centro de cualquier carnet en el pasado. Se enviará una notificación a todas las personas afectadas.',
        
    },
    
  documents_fac : {
    title: 'Documentos de la FAC',
    description: 'En esta sección podéis consultar los documentos disponibles para las asociaciones federadas.'
  },
    
  bookings: {
    create_user_button: 'Persona',
    create_user_title: 'Añadir persona',
    create_group_button: 'Grupo',
    create_group_title: 'Añadir grupo',

    status_0: 'Invitación',
    email_sent_0: 'Correo de invitación',
    email_sent_title_0: 'Correo de invitación',
    email_sent_ask_0: '¿Estás seguro de que quieres enviar el correo de invitación predeterminado?',
    email_sent_ask_all_0: '¿Estás seguro de que quieres enviar el correo de invitación predeterminado a todas las personas a las que aún no se les ha enviado?',
    email_sent_ask_again_0: 'El correo de invitación ya ha sido enviado anteriormente. ¿Estás seguro de que quieres enviarlo de nuevo?',
    email_send_all_button_0: 'Invitaciones',
    email_send_all_button_title_0: 'Enviar invitaciones',
    email_sent_ko_0: 'La invitación no se ha podido entregar al destinatario',
    email_sent_tooltip_button_0: 'Enviar invitación',
    email_sent_tooltip_sending_0: 'Se está enviando la invitación...',
    email_sent_tooltip_done_0: 'La invitación ha sido enviada',

    status_1: 'Recordatorio',
    email_sent_1: 'Correo de recordatorio',
    email_sent_title_1: 'Correo de recordatorio',
    email_sent_ask_1: '¿Estás seguro de que quieres enviar el correo de recordatorio predeterminado?',
    email_sent_ask_all_1: '¿Estás seguro de que quieres enviar el correo de recordatorio predeterminado a todas las personas a las que aún no se les ha enviado?',
    email_sent_ask_again_1: 'El correo de recordatorio ya ha sido enviado anteriormente. ¿Estás seguro de que quieres enviarlo de nuevo?',
    email_send_all_button_1: 'Recordatorios',
    email_send_all_button_title_1: 'Enviar recordatorios',
    email_sent_ko_1: 'El recordatorio no se ha podido entregar al destinatario',
    email_sent_tooltip_button_1: 'Enviar recordatorio',
    email_sent_tooltip_sending_1: 'Se está enviando el recordatorio...',
    email_sent_tooltip_done_1: 'El recordatorio ha sido enviado',

    email_sent_ok: '¡Envío realizado!',
    email_only_pending: 'A las personas a las que aún no se les ha enviado',
    email_all: 'A todas las personas',
    email_send_button: 'Enviar',
    email_send_all_advise: 'El envío masivo se ejecutará en segundo plano y puede tardar unos minutos.',

    email_status_0: '',
    email_status_1: 'Enviando...',
    email_status_2: '¡Error enviando!',
    email_status_3: '¡Enviado!',
    email_status_4: '¡Devuelto!',

    delete_all_button: 'Borrar',
    delete_all_button_title: 'Borrar personas',
    delete_all_ask: '¿Estás seguro de que quieres borrar a todas las personas del evento?',
    reminder: 'Enviar recordatorio a todas las personas',
    reminder_0: 'No enviar',
    reminder_1: 'El mismo día a las 7h',
    reminder_2: 'El mismo día a las 8h',
    reminder_3: 'El mismo día a las 9h',
    reminder_4: 'El mismo día a las 10h',
    reminder_5: 'El día anterior a las 19h',
    reminder_6: 'El día anterior a las 20h',
    reminder_7: 'El día anterior a las 21h',
    reminder_8: 'El día anterior a las 22h',

    exporter_file_name: 'personas_evento',
    exporter_header_id : 'Identificador',
  },
  social_nets : {
   title: 'Red social',
    link: 'Nombre de usuario'
  },
  users_families : {
    name : 'Nombre'
  },
  users_activities: {
    title: 'Actividades',
    create_button: 'Añadir',
    create: 'Añadir resumen anual de actividades',
    edit: 'Modificar resumen anual de actividades',
    name: 'Actividad',
    quantity: 'Cantidad',
    assistants: 'Asistentes',
    adult: 'Público',
    total_quantity: 'Número de actividades',
    total_assistants: 'Total de asistentes',
  },
  users_trainings : {
    title0: 'Formación en artes',
    title1: 'Formaciones',
    create_button: 'Añadir',
    create0: 'Añadir resumen anual de formaciones en artes',
    create1: 'Añadir resumen anual de formaciones',
    edit0: 'Modificar resumen anual de formaciones en artes',
    edit1: 'Modificar resumen anual de formaciones',
    type: 'Formación',
    formal: 'Tipo',
    students: 'Estudiantes',
    teachers_workers: 'Profesorado remunerado',
    teachers_volunteers: 'Profesorado voluntario',
    total_hours: 'Total de horas',
    total_quantity0: 'Número de formaciones en arte',
    total_quantity1: 'Número de formaciones',
    total_teachers_workers: 'Total profesorado remunerado',
    total_teachers_volunteers: 'Total profesorado voluntario',
    total_students: 'Total estudiantes',
    type0: 'Teatro',
    type1: 'Danza',
    type2: 'Circo',
    type3: 'Coral',
    type4: 'Canto',
    type5: 'Instrumentos musicales',
    type6: 'Artes plásticas',
    type7: 'Artes audiovisuales-digitales',
    type8: 'Escritura y expresión literaria',
    type9: 'Fotografía',
    type20: 'Idiomas',
    type21: 'Deportivas - Otras',
    type22: 'Deportivas - Fútbol',
    type23: 'Deportivas - Baloncesto',
    type24: 'Deportivas - Tenis de mesa',
    type25: 'Deportivas - Ajedrez',
    type26: 'Deportivas - Senderismo',
    type27: 'Curso Robótica/Tecnología',
    formal_art_0: 'Formal',
    formal_art_1: 'No formal',
    formal_0: 'Formal/federada',
    formal_1: 'No formal'
  },  
  users_social_projects: {
    title: 'Proyectos sociales',
    create_button: "Añadir",
    create: 'Añadir resumen anual de proyectos sociales',    
    edit: 'Modificar resumen anual de proyectos sociales',
    type: "Tipo de destinatario",
    quantity: "Cantidad",
    total_quantity: "Cantidad total",
    type0: 'Migración',
    type1: 'Violencia machista',
    type2: 'Discapacidad',
    type3: 'Desempleo',
    type4: 'Familias monoparentales',
    type5: 'Tercera edad (personas mayores)',
    type6: 'Salud mental',
    type7: 'Inserción sociolaboral',
    type8: 'Otros'
  },
  users_sections: {
    title: 'Secciones',
    create_button: 'Añadir',
    create: 'Añadir resumen anual de secciones',
    edit: 'Modificar resumen anual de secciones',
    participants: 'Participantes',
    num_sections: 'Núm. secciones',
    total_participants: 'Total participantes',
    name: 'Sección',
    quantity: 'Cantidad',
    assistants: 'Asistentes',
    total_quantity: 'Núm. actividades',
    total_assistants: 'Total asistentes',
  },
  users_shows: {
    title: 'Espectáculos',
    create_button: 'Añadir',
    create: 'Añadir resumen anual de espectáculos',
    edit: 'Modificar resumen anual de espectáculos',
    name: 'Espectáculo',
    total_quantity: 'Núm. funciones',
    total_assistants: 'Total asistentes',
    total_inversion: 'Total presupuesto',
    type: 'Tipo',
    type_1: 'Teatro',
    type_2: 'Danza',
    type_3: 'Música',
    type_4: 'Otros',
    amateur: 'Amateur/Profesional',
    amateur_1: 'Amateur',
    amateur_2: 'Profesional',
    adult: 'Público',
    adult_1: 'Infantil',
    adult_2: 'Adulto',
    adult_3: 'Para todos los públicos',
    quantity: 'Núm. funciones',
    assistants: 'Asistentes',
    inversion: 'Presupuesto',
    occupancy: 'Ocupación (%)',    
  },
  check_members: {
    not_found: 'El carnet no es válido',
    status_1: 'Válido',
    status_2: 'No válido',
    status_3: 'No válido',
    checker_name: 'Nombre y apellidos',
    checker_num_member: 'Número',
    checker_date: 'Fecha de validación',
  },
  users_annuary : {
    tab: 'Anuario',
    title: 'Formulario para el anuario',
    answered_status: 'Respondido',
    answered_status_0: 'No',
    answered_status_1: 'Sí',
    answered_date_time: 'Fecha de respuesta',
    expiration_date: 'Fecha límite para responder',
    sent_status: 'Envío',
    reminder_status: 'Recordatorio',
    confirm_status: 'Confirmación',
    send: 'Enviar',
    create_button: 'Enviar nuevo formulario',
    edit: 'Editar formulario',
    send_dialog_button: 'Formulario anuario',
    send_dialog_action: 'Acción a realizar',
    send_dialog_action_0: 'Enviar enlace del formulario a todos los contactos',
    send_dialog_action_1: 'Enviar recordatorio con el enlace al formulario a todos los contactos',
    send_dialog_text1_0: 'Se enviará un mensaje predefinido a todos los contactos del grupo a los que aún no se les haya enviado con el enlace para responder al formulario.',
    send_dialog_text1_1: 'Se enviará un recordatorio con el enlace del formulario a todos los miembros del grupo a los que ya se les haya enviado y aún no hayan respondido.',
    send_dialog_text2: 'La fecha límite para responder al formulario será la que indiques a continuación.',
    send_dialog_text3: 'El envío se ejecutará en segundo plano y puede tardar unos minutos. Podrás consultar el estado del envío y las respuestas en la ficha de cada contacto o usando los filtros de la lista de contactos.',
    
    not_found: 'El formulario no se ha encontrado o no está disponible',
    expired: 'Se ha acabado el plazo para responder al formulario, por favor, contacta con la FAC.',
    
    email_sent_0: 'Correo con el formulario',
    email_sent_title_0: 'Correo con enlace al formulario',
    email_sent_ask_0: '¿Seguro que quieres enviarle el enlace al formulario?',
    email_sent_ask_again_0: 'Ya se ha enviado previamente el correo con el enlace al formulario. ¿Seguro que quieres volver a enviárselo? Se generará un nuevo enlace.',
    email_sent_ko_0: 'No se ha podido entregar el formulario al destinatario',
    email_sent_tooltip_button_0: 'Enviar formulario',
    email_sent_tooltip_sending_0: 'Se está enviando el formulario...',
    email_sent_tooltip_done_0: 'Se ha enviado el formulario',
    
    email_sent_1: 'Correo de recordatorio',
    email_sent_title_1: 'Correo de recordatorio',
    email_sent_ask_1: '¿Seguro que quieres enviarle el correo de recordatorio predefinido?',
    email_sent_ask_again_1: 'Ya se ha enviado previamente el correo de recordatorio. ¿Seguro que quieres volver a enviarlo?',
    email_sent_ko_1: 'No se ha podido entregar el recordatorio al destinatario',
    email_sent_tooltip_button_1: 'Enviar recordatorio',
    email_sent_tooltip_sending_1: 'Se está enviando el recordatorio...',
    email_sent_tooltip_done_1: 'Se ha enviado el recordatorio',
    
    email_sent_2: 'Correo de confirmación',
    email_sent_title_2: 'Correo de confirmación',
    email_sent_ask_2: '¿Seguro que quieres enviarle el correo de confirmación predefinido?',
    email_sent_ask_again_2: 'Ya se ha enviado previamente el correo de confirmación. ¿Seguro que quieres volver a enviarlo?',
    email_sent_ko_2: 'No se ha podido entregar el correo de confirmación al destinatario',
    email_sent_tooltip_button_2: 'Enviar confirmación',
    email_sent_tooltip_sending_2: 'Se está enviando el correo de confirmación...',
    email_sent_tooltip_done_2: 'Se ha enviado el correo de confirmación',
    
    form_title: 'Formulario para el anuario %year%',
    form_send: 'Enviar',
    form_already_answered: 'El formulario ya ha sido respondido, si deseas modificar tus datos, por favor, contacta con la FAC.',
    form_general_data: 'Datos generales de la entidad',
    form_activities: 'Actividades',
    form_trainings_art: 'Formaciones en arte',
    form_trainings: 'Formaciones',
    form_classrooms: 'Espacios',
    form_social_nets: 'La entidad en la red',
    form_social_nets_title: 'Redes sociales',
    form_economic_data: 'Datos económicos',
    form_economic_data_incomings: 'Ingresos',
    form_economic_data_expenses: 'Gastos',
    form_economic_data_inversion: 'Inversión',
    form_shows: 'Espectáculos',
    form_sections: 'Secciones de la entidad',
    form_workers: 'Personal remunerado',
    form_volunteers: 'Voluntarios',
    form_edited_books_books: 'Libro del ateneo',
    form_edited_books_newsletters: 'Boletín electrónico',
    form_edited_books_magazines: 'Revista',
    form_entity: 'Nombre de la entidad',
    
    form_desc_activities: 'Las actividades que se desarrollan en tu ateneo. Diferentes de las formaciones y cursos.',
    form_desc_classrooms: 'Los espacios físicos o equipamientos que dispone tu ateneo.',
    form_desc_shows: 'Los actos que se representan en el ateneo.',
    form_desc_sections: 'Grupos de la entidad.',
    form_desc_trainings_art: 'Cursos con un fin formativo o de enseñanza en artes que se desarrollan en tu entidad.',
    form_desc_trainings: 'Cursos con un fin formativo o de enseñanza que se desarrollan en tu entidad.',
    form_desc_workers: 'Personas contratadas que reciben un salario en tu entidad.',
    form_desc_volunteers: 'Personas que ofrecen su tiempo, experiencia, conocimientos y trabajo para tu entidad sin recibir compensación económica.',
    form_desc_social_nets: 'Tu presencia en las redes sociales digitales.',
    form_desc_economic_data: 'Los datos generales de los gastos, ingresos e inversiones generales realizados en tu ateneo.',
    form_desc_edited_books_books: 'Libros publicados desde tu entidad.',
    form_desc_edited_books_newsletters: 'Tipo de publicaciones periódicas o que publicas regularmente con las noticias de tu ateneo.',
    form_desc_edited_books_magazines: 'Tipo de publicación periódica con artículos de información general o determinada sobre tu ateneo.'

  },
  users_economic_data : {
    incomings: 'Ingresos',
    expenses: 'Gastos',
    total: 'Resultado',
    inversion: 'Inversión en obras',
    members: 'Cuotas de socios',
    services: 'Servicios ofrecidos',
    subsidies: 'Subvenciones (Generalitat, Diputación, Ayuntamiento u otros)',
    rentals: 'Arrendamientos',
    products: 'Venta de productos',
    privates: 'Ayudas privadas',
    others: 'Otros',
    create: 'Añadir presupuesto anual',
    create_button: 'Añadir',
    edit_title: 'Modificar presupuesto anual',
    exp_rentals: 'Alquileres',
    exp_workers: 'Personal',
    exp_material: 'Material',
    exp_professionals: 'Profesionales externos',
    exp_groups: 'Cachets de grupos',
    exp_supplies: 'Suministros',
    exp_maintenance: 'Mantenimiento',
    exp_marketing: 'Comunicación y publicidad',
    exp_others: 'Otros',
    avg_quote: 'Media de la cuota de socio/a anual',
    detail: 'Detalle',
    inv_building: 'En el edificio en general',
    inv_theatre: 'En el teatro',
    inv_bar: 'En el bar',
    inv_accessibility: 'Accesibilidad',
    inv_security: 'Seguridad',
    inv_others: 'Otros',
        
  },
  
    users_demografy: {
        title: 'Datos demográficos',
        create: 'Añadir',
        edit_title: 'Modificar datos demográficos',
        year: 'Año',
        num_members: 'Número de socios',
        avg_age: 'Edad media',
        avg_age_managers: 'Edad media de la junta directiva',
        percent_man: 'Hombres (%)',
        percent_woman: 'Mujeres (%)',
        percent_no_binary: 'No binarios (%)'
    }, 

  users_workers : {
    create_0: 'Añadir resumen anual del personal remunerado',
    create_1: 'Añadir resumen anual del voluntariado',
    create_button: 'Añadir',
    edit_title_0: 'Modificar resumen anual del personal remunerado',
    edit_title_1: 'Modificar resumen anual del voluntariado',
    administration: 'Administración',
    activities: 'Técnico de actividades',
    concierge: 'Conserjería',
    clean_up: 'Limpieza',
    comunication: 'Comunicación',
    accounting: 'Contabilidad',
    maintenance: 'Mantenimiento',
    management: 'Gerencia',
    others: 'Otros',
    total_0: 'Total personal remunerado',
    total_1: 'Total personal voluntariado'
  },
  settings :{
    data: 'Datos de la entidad',
    data_desc: 'Nombre, correo electrónico, dirección, logotipo...',
    ibans: 'Bancos',
    ibans_desc: 'Configuración de cuentas bancarias',
    payment_systems: 'TPV',
    payment_systems_desc: 'Configuración de pasarelas bancarias',
    postal_labels: 'Etiquetas postales',
    postal_labels_desc: 'Configuración del formato de las etiquetas postales',
    alerts: 'Alertas',
    alerts_desc: 'Configuración de las alertas',
    emails: 'Plantillas de correos electrónicos',
    emails_desc: 'Contenidos de los correos electrónicos que reciben las personas',
    emails_logs: 'Registro de envío de correos electrónicos',
    emails_logs_desc: 'Consulta el registro de los envíos de correos electrónicos a los contactos',
    users: 'Usuarios administradores',
    users_desc: 'Personas y permisos de acceso',
    maintenance: 'Acciones de Superadmin',
    maintenance_desc: 'Procesos de base de datos...',
    profile: 'Perfil de usuario',
    profile_desc: 'Cambio de contraseña',
    subscription: 'Suscripción',
    subscription_desc: 'Cambiar la suscripción',
    returns_tax: 'Comisión de devolución (€)',
    bank_sufix: 'Sufijo ordenante',
    change_password: 'Modificar contraseña',

    add_member: 'Solicitud de alta de socios desde la web',
    add_member_desc: 'Configuración del formulario de inscripción',
    add_member_actived: 'Permitir la inscripción de socios desde la web',
    add_member_logo: 'Mostrar la cabecera con el logotipo y los datos de la entidad en el formulario',
    add_member_bank_data: 'Solicitar datos bancarios',
    add_member_notification: 'Enviar notificación a la entidad al recibir una solicitud',
    add_member_form_text: 'Texto explicativo del formulario (HTML)',
    add_member_lopd: 'Texto legal sobre el tratamiento de los datos (HTML)',
    add_member_form_sent: 'Texto de confirmación de formulario enviado (HTML)',
    add_member_help: '¿Cómo mostramos el formulario en nuestra página web?',
    add_member_help_1: 'Añade el siguiente código HTML a la página donde quieras mostrar el formulario de solicitud de socio:',
    add_member_view_form: 'Acceder al formulario',
    add_member_edit_form: 'Configurar formulario',
    add_member_pending_list: 'Acceder al listado de solicitudes' ,

    recercat_add_member: 'Formulario de solicitud del Carnet digital Recercat',
    recercat_add_member_desc: 'Configuración del formulario',
    recercat_add_member_actived: 'Formulario activo',
    recercat_add_member_form_sent: 'Texto de confirmación de formulario enviado',
    recercat_add_member_help_1: 'Añade el siguiente código HTML a la página donde quieras mostrar el formulario de solicitud de carnet',
                
    emails_subject: 'Asunto',
    emails_content: 'Contenido del mensaje',
    emails_message: 'Mensaje',
    emails_attached: 'Archivos',
    emails_copy: 'Copiar plantillas necesarias a las entidades',
    emails_copy_desc: 'Se copiarán las plantillas de correos a aquellas entidades que aún no las tengan. Útil cuando se cree una nueva plantilla. Si alguna entidad ya la tiene, no se sobrescribirá.',
    emails_vars: 'Variables disponibles',

    ibans_copy: 'Crear registro de IBAN\'s',
    ibans_copy_desc: 'Se copiarán los IBAN\'s actuales de todas las entidades a una tabla específica de IBAN\'s (si aún no se había hecho), para poder trabajar a partir de ahora con múltiples IBAN\'s por entidad.',
    done: 'Acción realizada',

    backup: 'Crear copia de seguridad',
    backup_desc: 'Se realizará una copia de seguridad de la base de datos en este momento.',

    remittances_subscriptions: 'Crear suscripción a Recibos para todas las entidades',
    remittances_subscriptions_desc: 'Se creará una suscripción a Recibos de todas las entidades que tengan la suscripción Gestión de entidades, siempre y cuando no la tengan ya, y no sean la entidad Recercat.',

    sa_postal_labels: 'Crear configuración de etiquetas postales para todas las entidades',
    sa_postal_labels_desc: 'Se creará una configuración por defecto para todas las entidades que aún no tengan ninguna configuración de etiquetas configurada.',

    emails_WELCOME: 'Correo de bienvenida de socio/a',
    emails_WELCOME_desc : 'Plantilla por defecto para los nuevos clientes',
    emails_WELCOME_vars : '%nom_entitat% | %nom% | %nom_complet% | %nom_grup% | %num_soci% | %data_ingres%',
    
    emails_GROUP_LEFT: "Correo de baja de miembro",
    emails_GROUP_LEFT_desc : 'Plantilla por defecto para infromar la baja de socio',
    emails_GROUP_LEFT_vars : '%nom_entitat% | %nom% | %nom_complet% | %nom_grup% | %num_soci% | %data_ingres% | %data_baixa%',
        
    emails_CLASSROOM_REQUEST: "SISTEMA - Notificación de que se ha recibido una solicitud de espacio",
    emails_CLASSROOM_REQUEST_desc: "Correo que reciben las entidades cuando hay una solicitud de uso de espacio",
    emails_CLASSROOM_REQUEST_vars : '%back_url% | %classroom_name% | %date_request% | %event_date% | %event_duration% | %event_recurrency% | %event_name% | %event_description% | %event_resources% | %comments% | %first_name% | %last_name_1% | %last_name_2% | %email% | %phone%',
        
    emails_ADDMEMBER_REQUEST : "SISTEMA - Notificación de que se ha recibido una solicitud de socio",
    emails_ADDMEMBER_REQUEST_desc: 'Correo que reciben las entidades cuando hay una solicitud de alta de socio',
    emails_ADDMEMBER_REQUEST_vars : '%back_url%',
        
    emails_UNDELIVERED_EMAILS: 'SISTEMA - Notificación de correos no enviados',
    emails_UNDELIVERED_EMAILS_desc: 'Correo que reciben las entidades cuando algunos correos no se han podido enviar',
    emails_UNDELIVERED_EMAILS_vars : '%emails_list%',
        
    emails_RESET_PSWD: 'SISTEMA - Notificación de recuperación de contraseña',
    emails_RESET_PSWD_desc: 'Correo que reciben los usuarios administradores si olvidan la contraseña',
    emails_RESET_PSWD_vars : '%reset_link%',
                
    emails_RECEIVE_RETURNED: 'Correo de recibo retornado (banco)',
    emails_RECEIVE_RETURNED_desc: 'Plantilla por defecto para los nuevos clientes',
    emails_RECEIVE_RETURNED_vars : '%nom% | %email_entitat%',
        
    emails_RECEIVE_RETURNED_CASH: 'Correo de recibo impagado (efectivo)',
    emails_RECEIVE_RETURNED_CASH_desc: 'Plantilla por defecto para los nuevos clientes',
    emails_RECEIVE_RETURNED_CASH_vars : '%nom% | %email_entitat%',
        
    emails_EVENT_INVITE: 'Correo de invitación a evento',
    emails_EVENT_INVITE_desc: 'Plantilla por defecto para los nuevos clientes',
    emails_EVENT_INVITE_vars : '%nom% | %nom_esdeveniment% | %activitat% | %data_ini% | %espai% | %email_entitat% | %url_event%',
        
    emails_EVENT_REMINDER: 'Correo de recordatorio de evento',
    emails_EVENT_REMINDER_desc: 'Plantilla por defecto para los nuevos clientes',    
    emails_EVENT_REMINDER_vars : '%nom% | %nom_esdeveniment% | %tipus% | %data_ini% | %data_fi% | %espai% | %url_event%',
        
    emails_ANNUARY_SEND: 'Correo con el enlace al formulario del anuario',
    emails_ANNUARY_SEND_desc: 'Plantilla por defecto para los nuevos clientes',
    emails_ANNUARY_SEND_vars : '%nom% | %year% | %date_expiration% | %link%',
        
    emails_ANNUARY_REMINDER: 'Correo de recordatorio con el enlace al formulario del anuario',
    emails_ANNUARY_REMINDER_desc: 'Plantilla por defecto para los nuevos clientes',
    emails_ANNUARY_REMINDER_vars :  '%nom% | %year% | %date_expiration% | %link%',
        
    emails_ANNUARY_CONFIRM: 'Correo de confirmación con el enlace a los datos introducidos',
    emails_ANNUARY_CONFIRM_desc: 'Plantilla por defecto para los nuevos clientes',
    emails_ANNUARY_CONFIRM_vars :  '%nom% | %year% | %date_expiration% | %link%',
        
    emails_DIGITAL_CARD_SEND: 'Correo de entrega del carnet digital',
    emails_DIGITAL_CARD_SEND_desc: 'Plantilla por defecto para los nuevos clientes',
    emails_DIGITAL_CARD_SEND_vars :  '%nom% | %nom_complet% | %nom_entitat% | %nom_grup% | %num_membre% | %google_wallet_link% | %google_wallet_image_and_link% | %apple_wallet_link% | %apple_wallet_image_and_link%',
    
    emails_RECERCAT_USER_VALIDATE_EMAIL: 'Correo de validación de la solicitud web',
    emails_RECERCAT_USER_VALIDATE_EMAIL_desc: 'Plantilla por defecto para los nuevos clientes',
    emails_RECERCAT_USER_VALIDATE_EMAIL_vars : '%nom_entitat% | %nom% | %nom_complet% | %nom_entitat% | %nom_centre% | %link%',
        
    emails_RECERCAT_USER_REQUEST_OK: 'Correo de solicitud aceptada',
    emails_RECERCAT_USER_REQUEST_OK_desc: 'Plantilla por defecto para los nuevos clientes',
    emails_RECERCAT_USER_REQUEST_OK_vars : '%nom_entitat% | %nom% | %nom_complet% | %nom_entitat% | %nom_centre% | %link%',
        
    emails_RECERCAT_USER_REQUEST_KO: 'Correo de solicitud errónea',
    emails_RECERCAT_USER_REQUEST_KO_desc: 'Plantilla por defecto para los nuevos clientes',
    emails_RECERCAT_USER_REQUEST_KO_vars : '%nom_entitat% | %nom% | %nom_complet% | %nom_entitat% | %nom_centre% | %link%',
        
    emails_RECERCAT_CARD_REVOKED_BY_IRMU_CENTER_DELETED: 'Correo de revocación de carnet debido a centro eliminado',
    emails_RECERCAT_CARD_REVOKED_BY_IRMU_CENTER_DELETED_desc: 'Plantilla por defecto para los nuevos clientes',
    emails_RECERCAT_CARD_REVOKED_BY_IRMU_CENTER_DELETED_vars : '%nom_entitat% | %nom% | %nom_complet% | %nom_entitat% | %nom_centre% | %link%',
        
    emails_RECERCAT_IRMU_RESUME: 'Correo de resumen (IRMU)',
    emails_RECERCAT_IRMU_RESUME_desc: 'Plantilla por defecto para los nuevos clientes',
    emails_RECERCAT_IRMU_RESUME_vars : '%nom_entitat% | %link% | %num_validats% | %num_pendents_irmu% | %num_pendents_centre%',
        
    emails_RECERCAT_CENTER_RESUME: 'Correo de resumen (centro)',
    emails_RECERCAT_CENTER_RESUME_desc: 'Plantilla por defecto para los nuevos clientes',
    emails_RECERCAT_CENTER_RESUME_vars : '%nom_entitat% | %nom_centre% | %num_membre% | %link% | %num_validats% | %num_pendents_irmu% | %num_pendents_centre%',
    
    emails_RECERCAT_REQUEST_DENIED_BY_IRMU : 'Correo de solicitud denegada desde IRMU',
    emails_RECERCAT_REQUEST_DENIED_BY_IRMU_desc : 'Plantilla por defecto para nuevos clientes',
    emails_RECERCAT_REQUEST_DENIED_BY_IRMU_vars : '%nom% | %nom_complet% | %nom_entitat% | %nom_centre%',
        
    emails_RECERCAT_REQUEST_DENIED_BY_CENTER: 'Correo de solicitud denegada desde el centro',
    emails_RECERCAT_REQUEST_DENIED_BY_CENTER_desc: 'Plantilla por defecto para los nuevos clientes',
    emails_RECERCAT_REQUEST_DENIED_BY_CENTER_vars : '%nom% | %nom_complet% | %nom_entitat% | %nom_centre%',
        
    emails_RECERCAT_CARD_REVOKED_BY_IRMU: 'Correo de carnet revocado desde el IRMU',
    emails_RECERCAT_CARD_REVOKED_BY_IRMU_desc: 'Plantilla por defecto para los nuevos clientes',    
    emails_RECERCAT_CARD_REVOKED_BY_IRMU_vars : '%nom% | %nom_complet% | %nom_entitat% | %nom_centre% | %num_membre%',
        
    emails_RECERCAT_CARD_REVOKED_BY_CENTER: 'Correo de carnet revocado desde el centro',
    emails_RECERCAT_CARD_REVOKED_BY_CENTER_desc: 'Plantilla por defecto para los nuevos clientes',
    emails_RECERCAT_CARD_REVOKED_BY_CENTER_vars : '%nom% | %nom_complet% | %nom_entitat% | %nom_centre% | %num_membre%',
                
    emails_ANNUARY_FORM_ANSWERED: 'FEDERACIÓN - Correo de notificación de respuesta de formulario del anuario',
    emails_ANNUARY_FORM_ANSWERED_desc: 'Solo lo recibe la FAC',
    emails_ANNUARY_FORM_ANSWERED_vars :  '%name% | %year%',
        
    emails_ANNUARY_FORM_NUM_MEMBERS: 'FEDERACIÓN - Correo de notificación de cambio de número de socios',
    emails_ANNUARY_FORM_NUM_MEMBERS_desc: 'Solo lo recibe la FAC',
    emails_ANNUARY_FORM_NUM_MEMBERS_vars :  '%nom% | %year% | %old_num_members% | %new_num_members%',
    
    emails_DONATIONS_USERS_CONFIRMATION: 'Donaciones: Correo de confirmación de una donación',
    emails_DONATIONS_USERS_CONFIRMATION_desc: 'Lo recibe el donante automáticamente en el momento de hacer la donación a través de la web',
    emails_DONATIONS_USERS_CONFIRMATION_vars :  '%nom% | %nom_complet% | %referencia% | %amount% | %nom_projecte% | %date_time% | %entitat_projecte%',
    
    emails_DONATIONS_USERS_PRE_CONFIRMATION_TRANSFER: 'Donaciones: Correo con instrucciones para hacer la transferencia bancaria',
    emails_DONATIONS_USERS_PRE_CONFIRMATION_TRANSFER_desc: 'Lo recibe el donante automáticamente en el momento de hacer la donación por transferencia bancaria',
    emails_DONATIONS_USERS_PRE_CONFIRMATION_TRANSFER_vars :  '%nom% | %nom_complet% | %referencia% | %amount% | %bank_ordering% | %bank_iban% | %bank_swift% | %nom_projecte% | %date_time% | %entitat_projecte%',
    
    emails_DONATIONS_USERS_CERTIFICATE: 'Correo de envío del certificado de donación',
    emails_DONATIONS_USERS_CERTIFICATE_desc: 'Lo recibe el donante',
    emails_DONATIONS_USERS_CERTIFICATE_vars : '%year% | %nom% | %nom_complet% | %nif% | %total_sum_donations%',
    
    emails_DONATIONS_RESUME_ASSOCIATION: 'Donaciones: Correo de resumen de las donaciones',
    emails_DONATIONS_RESUME_ASSOCIATION_desc: 'Lo reciben semanalmente las entidades. Un correo por cada proyecto de donaciones activas',
    emails_DONATIONS_RESUME_ASSOCIATION_vars : '%project_name% | %amount% | %count_donations% | %sum_donations% | %sum_donations_percent% | %avg_donations% | %date_start% | %date_finish% | %if_target_by_date_ini% | %if_target_by_date_end%',
    
    emails_DONATIONS_RESUME_FAC: 'Donaciones: Correo de resumen de las donaciones',
    emails_DONATIONS_RESUME_FAC_desc: 'Lo recibe semanalmente la FAC, con el resumen de todos los proyectos activos',
    emails_DONATIONS_RESUME_FAC_vars : '%iteration_project_ini% | %iteration_project_end% | %total_projects% | %total_sum_donations% | %total_count_donations% | %total_avg_donations% | %association_name% | %project_name% | %amount% | %count_donations% | %sum_donations% | %sum_donations_percent% | %avg_donations% | %date_start% | %date_finish% | %if_target_by_date_ini% | %if_target_by_date_end%',
      
    emails_DONATIONS_VALIDATE_REQUEST: 'Donaciones: Correo de solicitud de validación',
    emails_DONATIONS_VALIDATE_REQUEST_desc: 'Lo recibe la FAC cuando la entidad solicita validar el proyecto',
    emails_DONATIONS_VALIDATE_REQUEST_vars : '%association_name% | %project_name%',
    
    emails_DONATIONS_VALIDATE_ACCEPTED: 'Donaciones: Correo de validación de proyecto',
    emails_DONATIONS_VALIDATE_ACCEPTED_desc: 'Lo recibe la entidad cuando la FAC ha validado el proyecto.',
    emails_DONATIONS_VALIDATE_ACCEPTED_vars : '%association_name% | %project_name%',
    
    emails_DONATIONS_VALIDATE_REJECTED: 'Donaciones: Correo de denegación de validación de proyecto',
    emails_DONATIONS_VALIDATE_REJECTED_desc: 'Lo recibe la entidad cuando la FAC ha rechazado el proyecto.',
    emails_DONATIONS_VALIDATE_REJECTED_vars : '%association_name% | %project_name%',
        
    emails_NEW_ADMIN: 'SISTEMA - Correo de invitación de administrador (primera vez)',  
    emails_NEW_ADMIN_desc: 'Lo reciben los nuevos administradores',
    emails_NEW_ADMIN_vars : '%back_url% | %auth_user% | %auth_pswd% | %association_name%',  
    
    emails_NEW_ADMIN_PERMISSION : 'SISTEMA - Correo de invitación de administrador',  
    emails_NEW_ADMIN_PERMISSION_desc : 'Lo reciben los administradores que ya tienen un permiso previo.',  
    emails_NEW_ADMIN_PERMISSION_vars : '%back_url% | %auth_user% | %association_name%',  
    
    emails_ASSOCIATIONS_TRIAL: 'SISTEMA - Correo de soporte durante el período de prueba',  
    emails_ASSOCIATIONS_TRIAL_desc: 'Se envía manualmente a las asociaciones durante el período de prueba', 
    emails_ASSOCIATIONS_TRIAL_vars : '%nom% | %email%',  
    
    emails_ASSOCIATIONS_BEGIN: 'SISTEMA - Correo de inicio de la suscripción',  
    emails_ASSOCIATIONS_BEGIN_desc: 'Se envía manualmente a las asociaciones',
    emails_ASSOCIATIONS_BEGIN_vars : '%nom% | %email%',  
    
    emails_ASSOCIATIONS_END: 'SISTEMA - Correo de baja de la suscripción',  
    emails_ASSOCIATIONS_END_desc: 'Se envía manualmente a las asociaciones',
    emails_ASSOCIATIONS_END_vars : '%nom% | %email%',  
    
    emails_ALERT: 'SISTEMA - Alertas de las entidades',
    emails_ALERT_desc: 'Notificación de alerta',
    emails_ALERT_vars : '%association_name% | %message% | %date_alert%',
             
    emails_SKELETON: 'Esqueleto de todos los correos',
    emails_SKELETON_desc: 'Plantilla por defecto para los nuevos clientes',
    emails_SKELETON_vars : '%tema% | %contingut% | %nom_entitat% | %email_entitat% | %logotip%',
        
   emails_SA_SKELETON: 'SISTEMA - Esqueleto de todos los correos',
    emails_SA_SKELETON_desc: 'Esqueleto de todos los correos enviados desde el sistema',
    emails_SA_SKELETON_vars : '%tema% | %contingut%',
        
    emails_headers: 'Cabecera',
    emails_headers_sender_name: 'Nombre del remitente',
    emails_headers_sender_email: 'Correo electrónico del remitente',
    emails_headers_reply_to: 'Responder a',
    emails_headers_cc: 'Cc',
    emails_headers_bcc: 'Bcc',
    emails_headers_vars : '%nom_entitat% | %email_entitat%', 
        
  classrooms_requests: 'Solicitud de uso de espacio desde la web',
    classrooms_requests_desc: 'Configuración de la solicitud de espacios',
    classrooms_requests_actived: 'Permitir la solicitud de uso desde la web',
    classrooms_requests_logo: 'Mostrar la cabecera con el logotipo y los datos de la entidad en el formulario',
    classrooms_requests_notification: 'Enviar notificación a la entidad al recibir una solicitud',
    classrooms_requests_text: 'Texto en la cabecera de la página de resumen de espacios',
    classrooms_requests_lopd: 'Texto legal de política de protección de datos',
    classrooms_requests_form_sent: 'Texto de confirmación de formulario enviado',
    classrooms_requests_help: '¿Cómo mostramos los espacios en nuestra página web?',
    classrooms_requests_help_1: 'Añade el siguiente código HTML en la página donde quieras mostrar los espacios de la entidad:',
    classrooms_requests_view_form: 'Acceder a la web',
    
    donations_certificates: 'Certificado de donaciones',
    donations_certificates_desc: 'Configuración del contenido del certificado de donación (HTML)',
    donations_certificates_donations_users_certificate: 'Contenido del certificado de donación',
    donations_certificates_donations_users_certificate_vars_title: 'Variables disponibles',
    
    donations_certificates_donations_users_certificate_vars: '%year% | %entitat_nom% | %entitat_address% | %entitat_zip% | %entitat_city% | %entitat_country% | %entitat_nif% | %entitat_email% | %entitat_firma% | %nom% | %cognom1% | %cognom2% | %email% | %nom_complet% | %nif% | %address% | %zip% | %phone% | %city% | %country% | %iteration_donations_ini% | %amount% | %date_time% | %nom_projecte% | %justificacio_projecte% | %iteration_donations_end% | %total_sum_donations% | %data_certificat%',
    donations_certificates_header: 'Cabecera',
    donations_certificates_sign: 'Firma del responsable',
    donations_certificates_footer: 'Pie del certificado',
    
    docs: 'Documentos públicos',
    docs_desc: 'Imágenes, documentos... de la entidad accesibles a través de una URL pública',
    
   donations_own: 'Página de donativos',
    donations_own_desc: 'Configuración del diseño de la página de donativos',
    donations_own_donations_own_header: 'Cabecera',
    donations_own_donations_own_footer: 'Pie',
    donations_own_donations_own_privacy: 'Política de privacidad',
    donations_own_donations_own_terms: 'Términos y condiciones',
    donations_own_donations_own_fiscal: 'Beneficios fiscales',
    donations_own_donations_own_css: 'CSS adicional',
            
    annuary_form: 'Formulario para realizar el anuario',
    annuary_form_desc: 'Configuración del formulario para realizar el anuario',
    annuary_form_logo: 'Mostrar la cabecera con el logotipo y los datos de la FAC en el formulario',
    annuary_form_notification: 'Enviar notificación a la FAC al recibir una respuesta',
    annuary_form_notification_association: 'Enviar notificación a la entidad al responder el formulario con un enlace a sus respuestas',
    annuary_form_notification_num_members: 'Enviar notificación a la FAC al modificar el número de miembros de una entidad',
    annuary_form_text: 'Texto en la cabecera de la página',
    annuary_form_lopd: 'Texto legal de política de protección de datos',
    annuary_form_sent: 'Texto de confirmación de formulario enviado',
        
    templates_copied: 'Se han copiado las plantillas a todas las entidades',

    profile_user_tab: 'Usuario',
    profile_pswd_tab: 'Contraseña',
    profile_password: 'Nueva contraseña',
    profile_password_confirmation: 'Confirmar contraseña',
    profile_changed: 'Se ha modificado el perfil del usuario',
        
        
        
  },
  contacts : {
    title: 'Contactos de la FAC',
    description: 'En esta sección encontrarás una base de datos general y compartida para todos los ateneos con el objetivo de ayudaros a mantener actualizados los contactos de administraciones y contactos de interés. <br/>Os agradeceremos que si encontráis algún error nos lo hagáis saber para poder corregirlo lo antes posible.'
  },
  add_member : {
    association_not_found: 'No se ha encontrado la entidad',
    form_not_actived: 'Disculpen las molestias, el formulario no está activo.',
    form_title: 'Solicitud de alta de socio/a',
    recercat_form_title: 'Solicitud de carnet Recercat',
    recercat_association_not_found: 'La página no se ha encontrado',
    recercat_request_send: 'Enviar solicitud',
    form_lopd: 'Consiento el tratamiento de los datos para los fines descritos en el siguiente enlace:',
    form_lopd_validator: 'Debe consentir el tratamiento de los datos',
    lopd: 'Tratamiento de los datos',
  },
  classrooms_requests : {
    association_not_found: 'No se ha encontrado la entidad o el espacio no está disponible',
    form_not_actived: 'No hay espacios disponibles',
    form_title: 'Nuestros espacios',
    form_request_title: 'Solicitud de uso del espacio',
    form_event_data_title: 'Datos de la actividad:',
    form_requester_data_title: 'Datos del solicitante:',
    form_event_date: 'Fecha y hora',
    form_event_duration: 'Duración (horas)',
    form_event_recurrency: '¿Es una actividad recurrente? Indica todas las fechas.',
    form_event_name: 'Nombre de la actividad',
    form_event_description: 'Descripción',
    form_event_resources: 'Recursos que se necesitan para la actividad',
    form_event_comments: 'Otros comentarios',
    form_event_phone: 'Teléfono de contacto',
    form_send: 'Solicitar',
    form_doc: 'Ver ficha técnica',
    form_doc_title: 'ficha_técnica',
  },
  emails: {
    main_menu_title: 'Correos electrónicos enviados',
    create: 'Agregar correos',
    type: 'Plantilla',
    date_ordered: 'Fecha',
    from: 'Remitente',
    to: 'Destinatario',
    subject: 'Asunto',
    content: 'Contenido',
    docs: 'Adjuntos',
    date_sent: 'Fecha',
    status: 'Estado',
    error_message: 'Mensaje de error',
    status_0: 'No enviado',
    status_1: 'Enviando al servidor de correo...',
    status_2: 'Error en el servidor de correo',
    status_3: 'Enviado',
    status_4: 'Correo rechazado',
    exporter_file_name: 'emails',
    contact: 'Contacto',
    only_not_sent: 'Solo correos no enviados',
  }, 
  custom_fields: {
    main_menu_title: 'Campos adicionales',
    main_menu_desc: 'Administra campos adicionales para almacenar más datos de los contactos',
    target_2: 'Contactos',    
    title_2: 'Campos adicionales',
    target_31: 'Datos de federación',
    title_31: 'Campos adicionales de Federación',
    create: 'Añadir campo adicional',        
    name: 'Nombre',
    type: 'Tipo de campo',
    type_1: 'Texto corto',
    type_2: 'Texto largo',
    type_3: 'Texto con formato',
    type_4: 'Numérico',
    type_5: 'Fecha',
    type_6: 'Booleano S/N',
    type_7: 'Selección',
    type_8: 'Selección múltiple',
    required: 'Relleno obligatorio',
    max_length: 'Máx. longitud',
    default_value: 'Valor por defecto',
    actived: 'Visible',    
    priority: 'Orden',
    form_web: 'Visible en el formulario de alta de socio',
    form_annuary: 'Visible en el formulario del anuario',
    options: 'Opciones',
    delete_title: '¿Seguro que quieres borrar el campo adicional?',
    delete_ask: 'Se eliminarán todos los datos de los contactos introducidos en este campo adicional.',
  }, 
  custom_fields_options: {
    create: 'Añadir opción',    
    name: 'Opciones',
    priority: 'Orden',
    edit: 'Editar opción',
    delete_title: '¿Seguro que quieres borrar esta opción?',
    delete_ask: 'Se eliminarán todos los datos de los contactos introducidos con esta opción.',
  },
  resources : {
    users : {
      name: 'Contactos'
    },
    events : {
      name: 'Actividades'
    },
    groups : {
      name: 'Grupos'
    },
    real_state: {
      name: 'Inmuebles'      
    },
    agreements: {
      name: 'Convenios'
    },
    insurances: {
      name: 'Seguros'
    }
  },
  'ra-tree' : {
    action : {
      add_root : 'Añadir grupo'
    },
    new_node: 'Nuevo nodo',
    item_moved : 'Se ha desplazado el grupo'
  },
  'ra-search' : {
    recent : 'Búsquedas recientes'
  },


};

