
import * as React from 'react';

import { useState,useCallback, useEffect } from 'react';


import { Layout, AppBar, MenuItemLink, useDataProvider, UserMenu, useRefresh, useCreate, useUpdate  } from 'react-admin';

import { Link } from 'react-router-dom';
import {useHistory} from 'react-router-dom';






import { Typography } from '@material-ui/core';

import Grid from '@mui/material/Grid';


import PersonIcon from '@mui/icons-material/Person';
import CheckIcon from '@mui/icons-material/Check';

import { IconButton, Toolbar } from '@material-ui/core';


import { subscriptionModule, setActivePermissionData  } from '../common/utils';
import { MySearch  } from '../components/MySearch';
import { Alerts  } from '../components/Alerts';


import { useTranslate  } from '../common/language';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import SettingsIcon from '@mui/icons-material/Settings';

import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import Divider from '@mui/material/Divider';

import { ImageViewer } from './../resources/docs';
import { modules  } from './../common/constants';

const ConfigurationMenu = React.forwardRef((props, ref) => {
    
  var translate = useTranslate();
    
  return (
    <MenuItem
      ref={ref}
      component={Link}
      {...props}
      to="/settings-profile"
    >
      <ListItemIcon>
        <PersonIcon />
      </ListItemIcon>
      <ListItemText primary={translate('settings.profile')} secondary={localStorage.getItem('email')} />
    </MenuItem>
  );
});

const PermissionsMenu = React.forwardRef((props, ref) => {
    
  const refresh = useRefresh(); 
  var translate = useTranslate();
  const [create] = useCreate('auth/set-active-permission');
  
  var permissions = JSON.parse(localStorage.permissions);
    
  const handleClick = ( permission_id) => {
      
    create(            
      { payload: { data: permission_id } },
      {
        onSuccess: ({ data }) => {     
        },
        onFailure: ( error ) => {                          
        }
      }
    );
    
    setActivePermissionData(permission_id);
    
    // Refresca pàgina, anant a la pàgina inici
    if (window.location.href.includes('dashboard'))
        window.location.reload(false); 
    else
        window.location.href = '/#/dashboard';
    
  };
  return (
    <>
    {permissions.map((menuItem, index) => (
    <MenuItem 
      onClick={ (e) => { 
          e.stopPropagation();
          if (menuItem.id != localStorage.active_permission) handleClick(menuItem.id); 
      } }
      className={menuItem.id == localStorage.active_permission ? 'menu-permissions-selected' : ''} 
      key={menuItem.id}
    >
      <ListItemIcon>
      { menuItem.id == localStorage.active_permission ? <CheckIcon /> : null }
      </ListItemIcon>
      <ListItemText primary={menuItem.associations_name} secondary={translate('users_permissions.role_' + menuItem.permissions_groups)} />
    </MenuItem> )) }
    </>
  );
});

const MyUserMenu = props => (
  <UserMenu {...props}>
    <ConfigurationMenu />
    <Divider />
    <PermissionsMenu />
    <Divider />
  </UserMenu>
);

const MyAppBar = props => {

  var translate = useTranslate();
  const dataProvider = useDataProvider();
  const [info, setInfo] = useState();
    
  const [value, setValue] = React.useState(0);
  const associations_name = localStorage.getItem('associations_name');
    
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let members_group_link = '/groups-tree';
  if (localStorage.getItem('members_group_id') > 0)
    members_group_link = '/groups-tree/' + localStorage.getItem('members_group_id');
    
  const history = useHistory();
  const handleClickSettings = useCallback(() => history.push('/settings'), [history]);
        
  let current_path = history && history.location && history.location.pathname ? history.location.pathname : '';
        
  useEffect(() => {
        
  }, []);
    
  return (
    <>
            
      <AppBar {...props} className="appBar" userMenu={<MyUserMenu />}>
            
        <Grid container wrap="nowrap" className="appTitle">
          <Grid className="imgLogoContainer">
                
            { localStorage.getItem('associations_logo_doc') > 0 ? 
              <ImageViewer docs_id={localStorage.getItem('associations_logo_doc')} className="imgLogo" is_public={false} />
              : 
              window.location.hostname.replaceAll('.','')=='admingestioentitatscat' || window.location.hostname.replaceAll('.','')=='localhost' || subscriptionModule(modules.RECERCAT) ?
                <img className="imgLogo" src="/img/gestioentitatscat.png" />
                :
                <img className="imgLogo" src="/img/sifac.png" /> 
                            
            }
                
          </Grid>
          <Grid className="appTitleContainer">
            <Typography variant="h6" >{associations_name}</Typography>
          </Grid>
        </Grid>
            
        { localStorage.getItem('permissions_groups') === 'ADMIN' || localStorage.getItem('permissions_groups') === 'ADMIN_CONTACTS' || localStorage.getItem('permissions_groups') === 'ADMIN_READER' ? ( 
                
          <>
            <MySearch targets={[]} />
            { subscriptionModule(modules.ALERTS) ? 
            <Alerts /> 
            : null }   
            { subscriptionModule(modules.GESTIO) ?  
              <IconButton 
                onClick={handleClickSettings} >                
                <SettingsIcon />                
              </IconButton>
              : null }
          </>
            
        ) : 
                
          <>
            { subscriptionModule(modules.ALERTS) ? 
            <Alerts /> 
            : null }
            <IconButton 
              onClick={handleClickSettings} >                
              <SettingsIcon />                
            </IconButton>
          </>
    
        }
            

      </AppBar>
      <Toolbar className="appMenu">
        { localStorage.getItem('permissions_groups') === 'ADMIN' || localStorage.getItem('permissions_groups') === 'ADMIN_CONTACTS' || localStorage.getItem('permissions_groups') === 'ADMIN_READER' ? ( 
          <MenuItemLink className={'appMenuNew ' + (current_path=='/dashboard' ? 'selected' : '')} to="/dashboard" key="dashboard" primaryText={translate('main_menu.home') } exact {...props} />       
        ) : <span /> }

        { (localStorage.getItem('permissions_groups') === 'ADMIN' || localStorage.getItem('permissions_groups') === 'ADMIN_CONTACTS' || localStorage.getItem('permissions_groups') === 'ADMIN_READER' ) && subscriptionModule(modules.GESTIO) ? ( 
          <MenuItemLink className={'appMenuNew ' + ((current_path.includes('/users') || current_path.includes('/groups-tree/')) ? 'selected' : '')} key="users" to={members_group_link} primaryText={subscriptionModule(modules.RECERCAT) ? translate('main_menu.recercat_users') : translate('main_menu.users')} exact {...props}  />
        ) : <span /> }
    
        { (localStorage.getItem('permissions_groups') === 'ADMIN' || localStorage.getItem('permissions_groups') === 'ADMIN_CONTACTS' || localStorage.getItem('permissions_groups') === 'ADMIN_READER' ) && subscriptionModule(modules.RECERCAT) ? ( 
          <MenuItemLink className={'appMenuNew ' + (current_path.includes('/recercat_centers') ? 'selected' : '')} key="recercat_centers" to="/recercat_centers" primaryText={translate('main_menu.recercat_centers')} exact {...props} />
        ) : <span /> }
    
        { (localStorage.getItem('permissions_groups') === 'ADMIN' || localStorage.getItem('permissions_groups') === 'ADMIN_CONTACTS' || localStorage.getItem('permissions_groups') === 'ADMIN_READER' ) && subscriptionModule(modules.CONTACTS_FAC) ? ( 
          <MenuItemLink className={'appMenuNew ' + (current_path.includes('/groups-tree-contacts') ? 'selected' : '')} key="contacts" to="/groups-tree-contacts" primaryText={translate('main_menu.contacts')} exact {...props} />
        ) : <span /> }

        { (localStorage.getItem('permissions_groups') === 'ADMIN' || localStorage.getItem('permissions_groups') === 'ADMIN_CONTACTS' || localStorage.getItem('permissions_groups') === 'ADMIN_READER' ) && subscriptionModule(modules.REMITTANCES) ? ( 
          <MenuItemLink className={'appMenuNew ' + (current_path.includes('/remittances') ? 'selected' : '')} key="remittances" to="/remittances" primaryText={translate('main_menu.remittances')} exact {...props} />
        ) : <span /> }
    
        { (localStorage.getItem('permissions_groups') === 'ADMIN' || localStorage.getItem('permissions_groups') === 'ADMIN_CONTACTS' || localStorage.getItem('permissions_groups') === 'ADMIN_READER' ) && subscriptionModule(modules.CALENDAR) ? ( 
          <MenuItemLink className={'appMenuNew ' + (current_path.includes('/classrooms') ? 'selected' : '')} key="classrooms" to="/classrooms" primaryText={translate('main_menu.classrooms')} exact {...props} />
        ) : <span /> }

        { (localStorage.getItem('permissions_groups') === 'ADMIN' || localStorage.getItem('permissions_groups') === 'ADMIN_CONTACTS' || localStorage.getItem('permissions_groups') === 'ADMIN_READER' ) && subscriptionModule(modules.REAL_STATE) ? ( 
          <MenuItemLink className={'appMenuNew ' + (current_path.includes('/real_state') ? 'selected' : '')} key="real_state" to="/real_state" primaryText={translate('main_menu.real_state')} exact {...props} />
        ) : <span /> }

        { (localStorage.getItem('permissions_groups') === 'ADMIN' || localStorage.getItem('permissions_groups') === 'ADMIN_CONTACTS' || localStorage.getItem('permissions_groups') === 'ADMIN_READER' ) && subscriptionModule(modules.AGREEMENTS) ? ( 
          <MenuItemLink className={'appMenuNew ' + (current_path.includes('/agreements') ? 'selected' : '')} key="agreements" to="/agreements" primaryText={translate('main_menu.agreements')} exact {...props} />
        ) : <span /> }

        { (localStorage.getItem('permissions_groups') === 'ADMIN' || localStorage.getItem('permissions_groups') === 'ADMIN_CONTACTS' || localStorage.getItem('permissions_groups') === 'ADMIN_READER' ) && subscriptionModule(modules.INSURANCES) ? ( 
          <MenuItemLink className={'appMenuNew ' + (current_path.includes('/insurances') ? 'selected' : '')} key="insurances" to="/insurances" primaryText={translate('main_menu.insurances')} exact {...props} />
        ) : <span /> }
    
        { (localStorage.getItem('permissions_groups') === 'ADMIN' || localStorage.getItem('permissions_groups') === 'ADMIN_CONTACTS' || localStorage.getItem('permissions_groups') === 'ADMIN_READER' ) && subscriptionModule(modules.CALENDAR) ? ( 
          <MenuItemLink className={'appMenuNew ' + (current_path.includes('/events') ? 'selected' : '')} key="events" to="/events" primaryText={translate('main_menu.events')} exact {...props} />
        ) : <span /> }
    
        { (localStorage.getItem('permissions_groups') === 'ADMIN' || localStorage.getItem('permissions_groups') === 'ADMIN_CONTACTS' || localStorage.getItem('permissions_groups') === 'ADMIN_READER' ) && (subscriptionModule(modules.FEDERATION) || subscriptionModule(modules.DONATIONS,modules.DONATIONS_OWN) || subscriptionModule(modules.REMITTANCES) ) ? ( 
          <MenuItemLink className={'appMenuNew ' + (current_path.includes('/reports') ? 'selected' : '')} key="reports" to="/reports" primaryText={translate('main_menu.reports')} exact {...props} />
        ) : <span /> }
        
        { (localStorage.getItem('permissions_groups') === 'ADMIN' || localStorage.getItem('permissions_groups') === 'ADMIN_CONTACTS' || localStorage.getItem('permissions_groups') === 'ADMIN_READER' ) && subscriptionModule(modules.DOCUMENTS_FAC) ? ( 
          <MenuItemLink className={'appMenuNew ' + (current_path.includes('/docs_fac') ? 'selected' : '')} key="docs_fac" to="/docs_fac" primaryText={translate('main_menu.docs_fac')} exact {...props} />
        ) : <span /> }
    
        { localStorage.getItem('permissions_groups') === 'SIFAC' ? ( 
          <MenuItemLink className={'appMenuNew ' + (current_path=='/dashboard' ? 'selected' : '')} key="" to="/dashboard" primaryText={translate('main_menu.associations')} exact {...props} />       
        ) : <span /> }

        { localStorage.getItem('permissions_groups') === 'SIFAC' ? ( 
          <MenuItemLink className={'appMenuNew ' + (current_path.includes('/modules') ? 'selected' : '')} key="modules" to="/modules" primaryText={translate('main_menu.modules')} exact {...props}  />
        ) : <span /> }
        
        { (localStorage.getItem('permissions_groups') === 'ADMIN' || localStorage.getItem('permissions_groups') === 'ADMIN_READER' ) && subscriptionModule(modules.DONATIONS) ? ( 
        <MenuItemLink className={'appMenuNew ' + (current_path.includes('/donations_projects') && !current_path.includes('/reports') ? 'selected' : '')} key="donations_projects" to="/donations_projects" primaryText={translate('donations_projects.main_menu_title')} exact {...props} />        
        ) : <span /> }

      </Toolbar>
    </>
  );
};


export const MyLayout = props => {
  return (
    <>
    <Layout 
      {...props} 
      menu={''}
      className={window.location.hostname.replaceAll('.','') + ' ' + (subscriptionModule(modules.RECERCAT) ? 'recercat' : '')}
      appBar={MyAppBar} />
      <div id="mainLoading" className="loading"><div></div></div>
    </>
  );
};

