import React, { useState } from 'react';

import {
  List, 
  Datagrid,
  TextField,
  DeleteButton,
  Edit,
  SelectInput,
  TextInput,
  Button ,
  Create,
  SimpleForm,
  CreateButton ,
  TopToolbar,
  FormTab,
  TabbedForm,
  ReferenceManyField,
  FormDataConsumer,
  useListContext, required,NumberInput,BooleanInput,SelectField,  FunctionField, ResourceContextProvider, useCreate, useRefresh, FormWithRedirect, Toolbar, SaveButton,
  useNotify,
  useRedirect,
  DateField  , 
  Filter, 
  downloadCSV,
  DateInput,
  SimpleFormIterator,
  ArrayInput,BooleanField,DateTimeInput, ReferenceInput, EditButton, NumberField, useDataProvider, ShowButton, HttpError 
} from 'react-admin';

import { useEffect } from 'react';
import {useHistory} from 'react-router-dom';

import jsonExport from 'jsonexport/dist';

import { CardHeader } from '@material-ui/core';

import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import EditIcon from '@mui/icons-material/Edit';

import { green, orange, deepOrange } from '@mui/material/colors';

import PreviewIcon from '@mui/icons-material/Preview';
import PublicIcon from '@mui/icons-material/Public';

import Tooltip from '@mui/material/Tooltip';

import { AccordionSection  } from '@react-admin/ra-form-layout';


import { makeStyles } from '@material-ui/core/styles';

import Divider from '@mui/material/Divider';


import { useTranslate  } from '../common/language';

import Typography from '@mui/material/Typography';


import RichTextInput from 'ra-input-rich-text';


import { TextFieldShow } from '../components/TextFieldShow';

import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Chip from '@material-ui/core/Chip';

import { ColorInput } from 'react-admin-color-input';

import { DocsList, DocsListShow } from './docs';
import { targets, modules, modulesOptions, choicesClassroomsType, choicesDonationsProjectsFacValidationStatus, donationsUrl  } from './../common/constants';
import { dateOnlyToCatalan, subscriptionModule, setActivePermissionData, structArrayToIdList, dateParser, toIsoString, LinearProgressWithLabel, RemainingTime, DateTimeField, DatagridNoResults } from './../common/utils';  

import { CityInput } from './../components/Inputs';
import { ExportButton } from './../components/ExportButton';
import { BackLink } from './../components/BackLink';
import { CreateDonationButton } from './../resources/donations_users';

import { RenderBoolean } from '../components/User';

import Box from '@material-ui/core/Box';

import { Link } from 'react-router-dom';

import { validateMaxLength, validateInteger, validateDoubleGreatOrEqualThan  } from './../components/Validator';


export const DonationsProjectsList = (props) => {
  const translate = useTranslate();  
  const history = useHistory();
    
  let basePath='/donations_projects'; 
  let resource = 'donations_projects';
  
  let current_path = history && history.location && history.location.pathname ? history.location.pathname : '';
  let report = current_path.includes('/reports');
  
  const handleClickView = (friendly_url) => {        
    const url = donationsUrl + '/' + friendly_url;
    window.open(url, '_blank'); // Abre la URL en una nueva pestaña    
  };
  
  const handleClickDonations = (id) => {  
    if (report)
        history.push('/reports/donations_users/' + id );    
    else
        history.push('/donations_users/' + id);    
  };
  
  const handleClickPreview = (friendly_url, hash_preview) => {        
    const url = donationsUrl + '/' + friendly_url + '?hash_preview=' + hash_preview;
    window.open(url, '_blank'); 
  };
        
  return (
    <ResourceContextProvider value={resource}>
      <List 
        basePath={basePath}   
        filter={{ associations_id : localStorage.getItem('associations_id'), report: report }}  
        actions={<ListActions report={report}  />}    
        bulkActionButtons={false}          
        perPage={1000}   
        pagination={false}
        sort={{ field: 'id', order: 'ASC' }} 
        label=""  
        title=""  
        resource={resource}
        filters={ <MyFilter report={report} /> }         
        filterDefaultValues={ { only_current : false, q: "", only_pending: false }}
      >        
        <Datagrid
            empty={<DatagridNoResults />} 
        >  
          { report ? <TextField source="associations_name" label={translate('donations_projects.associations_name')} sortable={false} />  : null }
          <TextField source="name" label={translate('donations_projects.name')} sortable={false} />            
          <DateTimeField source="date_start" label={translate('donations_projects.date_start')} sortable={false} />  
          <DateTimeField source="date_finish" label={translate('donations_projects.date_finish')} sortable={false} />  
          <FunctionField label={translate('donations_projects.amount')} render={record => record.target_by_date ?
            <NumberField source="amount"  sortable={false} locales="es-ES" options={{ style: 'currency', currency: 'EUR' }} />            
            :
            <span />          
          } />
          
          <FunctionField render={record => <Chip onClick={handleClickDonations.bind(this, record.id )} color="secondary" label={record.count_donations} /> } label={translate('donations_projects.count_donations')} sortable={false}  />                   
          <NumberField source="sum_donations" label={translate('donations_projects.sum_donations')} sortable={false} locales="es-ES" options={{ style: 'currency', currency: 'EUR' }} />  
          <FunctionField render={record => record.target_by_date ? <LinearProgressWithLabel value={record.sum_donations_percent} /> : null } label={translate('donations_projects.sum_donations_percent')} sortable={false}  />         
          <FunctionField render={record => record.target_by_date ? <RemainingTime nSeconds={record.remaining_seconds} /> : null } label={translate('donations_projects.remaining_days')} sortable={false}  />           
          { report || subscriptionModule(modules.DONATIONS,modulesOptions.DONATIONS_FAC ) ?
          <FunctionField label={translate('donations_projects.fac_validation_status')} render={record => <RenderStatus fac_validation_status={record.fac_validation_status} record={record} /> } sortable={false}  />
          : null }
          <FunctionField render={record => (!report && !record.public_entity) || (report && record.fac_validation_status == 1) ? <PreviewIcon onClick={handleClickPreview.bind(this, record.friendly_url, record.hash_preview)} /> : null } label={translate('donations_projects.preview')} sortable={false}  />
          <FunctionField render={record => record.public_entity ? <PublicIcon onClick={handleClickView.bind(this, record.friendly_url )} /> : null } label={translate('donations_projects.view')} sortable={false}  />
          
          { report ?
          <NumberField source="priority" label={translate('common.priority')} sortable={false} />
          : null }
          
          { !report ? <EditButton /> : null }
          
          <FunctionField render={record => 
            !report && subscriptionModule(modules.DONATIONS,modulesOptions.DONATIONS_FAC ) && (record.fac_validation_status == 0 || record.fac_validation_status == 3) ? <ValidateRequestButton record={record} /> : 
            report ? <ValidateButton record={record} /> : null
          } label={translate('donations_projects.fac_validation_actions')} sortable={false}  /> 
                  
          { report ?
          <FunctionField render={record => record.fac_validation_status == 2 ? <CreateDonationButton donations_projects_id={record.id} /> : null } label="" sortable={false}  />           
          : null }
                  
        </Datagrid>        
      </List>
    </ResourceContextProvider>
  );
};

export const DonationsPaymentsList = (props) => {
  const translate = useTranslate();  
  const history = useHistory();
    
  let basePath='/donations_projects/payments'; 
  let resource = 'donations_projects/payments';
        
  return (
    <ResourceContextProvider value={resource}>
      <List 
        basePath={basePath}   
        filter={{ associations_id : localStorage.getItem('associations_id'), date_from: props.match.params.date_from, date_to: props.match.params.date_to  }}  
        actions={<ListActionsPayments {...props} />}            
        bulkActionButtons={false}          
        perPage={10000}   
        pagination={false}
        sort={{ field: 'id', order: 'ASC' }} 
        label=""  
        title=""  
        resource={resource}
        filters={ <MyFilterPayments /> }         
        filterDefaultValues={ { q: "" }}
      >        
        <Datagrid
            empty={<DatagridNoResults />}
        >  
          <TextField source="associations_name" label={translate('donations_projects.associations_name')} sortable={false} />
          <TextField source="name" label={translate('donations_projects.name')} sortable={false} />        
          <TextField source="ibans_iban" label={translate('donations_projects.ibans_iban')} sortable={false} />         
          <TextField source="count_donations" label={translate('donations_projects.count_donations')} sortable={false} locales="es-ES" options={{ style: 'currency', currency: 'EUR' }} />            
          <NumberField source="sum_donations" label={translate('donations_projects.sum_donations')} sortable={false} locales="es-ES" options={{ style: 'currency', currency: 'EUR' }} />                      
        </Datagrid>        
      </List>
    </ResourceContextProvider>
  );
};

/*<Avatar><VolunteerActivismIcon/></Avatar>*/

const RenderStatus = (props) => {
    const translate = useTranslate(); 

    if (props.fac_validation_status === 0) 
        return <Tooltip title={translate('donations_projects.fac_validation_status_0')}>
            <Avatar sx={{ bgcolor: orange[500] , width: 30, height: 30 }} ><EditIcon /></Avatar>
        </Tooltip>;
    else if (props.fac_validation_status === 1) 
        return <Tooltip title={translate('donations_projects.fac_validation_status_1')}>
                <Avatar sx={{ bgcolor: orange[500] , width: 30, height: 30 }} ><PendingActionsIcon /></Avatar>
        </Tooltip>;
    else if (props.fac_validation_status === 2)
        return <Tooltip title={translate('donations_projects.fac_validation_status_2')}>
            <Avatar sx={{ bgcolor: green[500] , width: 30, height: 30 }} ><DoneIcon /></Avatar>
        </Tooltip>;
    else if (props.fac_validation_status === 3)
        return <Tooltip title={translate('donations_projects.fac_validation_status_3')}>
            <Avatar sx={{ bgcolor: deepOrange[500] , width: 30, height: 30 }} ><CloseIcon /></Avatar>
        </Tooltip>;        
    else
        return null;
}

const MyFilter = (props) => {
    const translate = useTranslate(); 
    return (
    <Filter {...props}>         
        <TextInput label={translate('common.search')} source="q" alwaysOn validate={[validateMaxLength(50,translate('common.validation.maxLength'))]} resettable /> 
        <BooleanInput label={translate('donations_projects.filter_only_current')} source="only_current" alwaysOn />
        { props.report ? <BooleanInput label={translate('donations_projects.only_pending')} source="only_pending" alwaysOn /> : null }
    </Filter>
);
};

const MyFilterPayments = (props) => {
    const translate = useTranslate(); 
    return (
    <Filter {...props}>         
        <TextInput label={translate('common.search')} source="q" alwaysOn validate={[validateMaxLength(50,translate('common.validation.maxLength'))]} resettable />         
    </Filter>
);
};

export const DonationsProjectsDetails = ({ id, record, resource, ...props }) => {
  const translate = useTranslate(); 
        
  return (
    <div className="usersClassroomsExpand">        
      <Grid container spacing={2}>
        <Grid item xs={12}>   
          <TextFieldShow source={record.name} label={translate('donations_projects.name')} />
        </Grid>      
        <Grid item xs={3}>   
          <TextFieldShow source={record.target_by_date} label={translate('donations_projects.target_by_date')} />
        </Grid> 
        <Grid item xs={3}>   
          <TextFieldShow source={dateOnlyToCatalan(record.date_start)} label={translate('donations_projects.date_start')} />
        </Grid> 
        <Grid item xs={3}>   
          <TextFieldShow source={dateOnlyToCatalan(record.date_finish)} label={translate('donations_projects.date_finish')} />
        </Grid> 
        <Grid item xs={3}>   
          <TextFieldShow source={record.minimum_amount} label={translate('donations_projects.minimum_amount')} />
        </Grid> 
        <Grid item xs={3}>   
          <TextFieldShow source={record.amount} label={translate('donations_projects.amount')} />
        </Grid> 
        <Grid item xs={12}>   
          <TextFieldShow source={record.description_short} label={translate('donations_projects.description_short')} />
        </Grid> 
        <Grid item xs={12}>   
          <TextFieldShow source={record.description} label={translate('donations_projects.description')} />
        </Grid> 
        <Grid item xs={12}>   
          <TextFieldShow source={record.video} label={translate('donations_projects.video')} />
        </Grid>
        <Grid item xs={3}>   
          <TextFieldShow source={<RenderBoolean source={record.public_entity} />} label={translate('donations_projects.public_entity')} />
        </Grid> 
        <Grid item xs={3}>   
          <TextFieldShow source={<RenderBoolean source={record.public_fac} />} label={translate('donations_projects.public_fac')} />
        </Grid> 
        <Grid item xs={3}>   
          <TextFieldShow source={record.payment_systems_id} label={translate('donations_projects.payment_systems_id')} />
        </Grid> 
        <Grid item xs={3}>   
          <TextFieldShow source={record.ibans_id} label={translate('donations_projects.ibans_iban')} />
        </Grid> 
        <Grid item xs={12}>   
          <TextFieldShow source={record.legal_text_1} label={translate('donations_projects.legal_text_1')} />
        </Grid> 
        <Grid item xs={12}>   
          <TextFieldShow source={record.legal_text_2} label={translate('donations_projects.legal_text_2')} />
        </Grid> 
        <Grid item xs={12}>   
          <TextFieldShow source={record.confirmation_text_1} label={translate('donations_projects.confirmation_text_1')} />
        </Grid> 
        <Grid item xs={12}>   
          <TextFieldShow source={record.confirmation_text_2} label={translate('donations_projects.confirmation_text_2')} />
        </Grid> 
        <Grid item xs={12}>   
          <TextFieldShow source={record.confirmation_email_1} label={translate('donations_projects.confirmation_email_1')} />
        </Grid> 
      </Grid>
      
    </div>
  );
};

const exporter = (items, translate, notify) => {
    
  const itemsForExport = items.map(item => {
    var itemForExport = {
        id: item.id,
        name: item.name,
        description_short: item.description_short,
        description: item.description,
        video: item.video,
        date_start: dateOnlyToCatalan(item.date_start),
        date_finish: dateOnlyToCatalan(item.date_finish),
        minimum_amount: item.minimum_amount,
        amount: item.amount,
        public_entity: item.public_entity,
        public_fac: item.public_fac,
        payment_systems_id: item.payment_systems_id,
        ibans_id: item.ibans_id,        
        public_sum_donations: item.public_sum_donations
    };
    return itemForExport;
  });
  jsonExport(itemsForExport, {
    headers: ['id','name'
,'description_short'        
,'description'
,'video'
,'date_start'
,'date_finish'
,'minimum_amount'
,'amount'
,'public_entity'
,'public_fac'
,'payment_systems_id'
,'ibans_id'
,'public_sum_donations'
],
    rename: [translate('common.id'),translate('donations_projects.name'),translate('donations_projects.description_short'), translate('donations_projects.description'),translate('donations_projects.video'),translate('donations_projects.date_start'),translate('donations_projects.date_finish'),translate('donations_projects.minimum_amount'),translate('donations_projects.amount'),translate('donations_projects.public_entity'),translate('donations_projects.public_fac'),translate('donations_projects.payment_systems_id'),translate('donations_projects.ibans_iban'),translate('donations_projects.public_sum_donations')],        
        
  }, (err, csv) => {
    const csv_new = '\uFEFF' + csv;
    downloadCSV(csv_new, translate('donations_projects.exporter_file_name') ); 
    notify(translate('common.download_ok'),"success");
  });
};


const exporterPayments = (items, translate, notify) => {
    
  const itemsForExport = items.map(item => {
    var itemForExport = {
        associations_id: item.associations_id,
        associations_name: item.associations_name,
        id: item.id,        
        name: item.name,        
        ibans_iban: item.ibans_iban,
        count_donations: item.count_donations,
        sum_donations: item.sum_donations != null ? item.sum_donations.toFixed(2).replace('.', ',') : '',  
    };
    return itemForExport;
  });
  jsonExport(itemsForExport, {
    headers: ['associations_id',
        'associations_name',
        'id',
        'name',
        'ibans_iban',
        'count_donations',
        'sum_donations',
        ],
    rename: [translate('donations_projects.associations_id'),translate('donations_projects.associations_name'),
        translate('donations_projects.id'),translate('donations_projects.name'),translate('donations_projects.ibans_iban'),
        translate('donations_projects.count_donations'),translate('donations_projects.sum_donations')],                
  }, (err, csv) => {
    const csv_new = '\uFEFF' + csv;
    downloadCSV(csv_new, translate('donations_payments.exporter_file_name') ); 
    notify(translate('common.download_ok'),"success");
  });
};


const ListActions = (props) => {
    const translate = useTranslate(); 
        
    let filterExport = { 
        associations_id: localStorage.getItem('associations_id'),    
        report: props.report,
        q: props.filterValues && props.filterValues.q ? props.filterValues.q : '',
        only_pending: props.report && props.filterValues && props.filterValues.only_pending ? props.filterValues.only_pending : false
    };
    
    const openUrlInNewWindow = () => {
        
        let url_association = localStorage.getItem('associations_id') == 234 ? '/fundacio-la-roda' : '/entitats/' + localStorage.getItem('associations_id');
        
        const url = !props.report 
            ? donationsUrl + url_association
            : donationsUrl;
        window.open(url, '_blank');
    };

    return (
    <TopToolbar>     
        { !props.report ? <MyCreateButton {...props} /> : null }   
        <ShowButton 
            onClick={openUrlInNewWindow}            
            label={translate('donations_projects.view_web')}
            target="_blank"
        />
        <ExportButton maxResults="1000000" filterValues={filterExport} alwaysEnabled={false} exporter={exporter} sort={{ field: 'id', order: 'DESC' }} /> 
    </TopToolbar>
    );
};

/*
 * component={Link}
            to={{
                pathname: !props.report ? donationsUrl + '/entitats/' + localStorage.getItem('associations_id') : donationsUrl
            }} 
 */

const ListActionsPayments = (props) => {
    const translate = useTranslate(); 
        console.log(props);        
        
    let filterExportPayments = { 
        associations_id: localStorage.getItem('associations_id'),  
        date_from: props.match.params.date_from,
        date_to: props.match.params.date_to,
        q: props.filterValues && props.filterValues.q ? props.filterValues.q : ''
    };
    
    return (
    <TopToolbar>     
        <ExportButton maxResults="1000000" filterValues={filterExportPayments} alwaysEnabled={false} exporter={exporterPayments} sort={{ field: 'id', order: 'DESC' }} /> 
    </TopToolbar>
    );
};

export const MyCreateButton = ({ users_id , ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('donations_projects');
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  
  const redirect = useRedirect();
        
  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    create(            
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {                    
          redirect('/donations_projects/' + data.id);
        
        },
        onFailure: ( error ) => {
          notify( error.message , 'error');
        }
      }
    );
  };
    
  return (
    <>            
      <Button onClick={handleClick} label={translate('donations_projects.create')}>
        <IconContentAdd />                
      </Button>

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('donations_projects.create')}
      >
        <DialogTitle>{translate('donations_projects.create')}</DialogTitle>

        <FormWithRedirect
          save={handleSubmit}
          initialValues={{ associations_id: localStorage.getItem('associations_id'), payment_systems_fac: subscriptionModule(modules.DONATIONS,modulesOptions.DONATIONS_FAC) ? true : false, payment_card: true }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <DialogContent>
                <Grid container spacing={1}>                                     
                  <Grid item xs={12}>                                               
                    <TextInput fullWidth source="name" label={translate('common.name')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} />
                  </Grid>                  
                </Grid> 
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

const MyActions = ({ basePath, data, resource }) => {
  
  let backLink = '/donations_projects';
  return <BackLink link={backLink} title={ data && data.name ? data.name : null } />;
  
};


export const DonationsProjectsEdit  = ({ id, ...props }) => {

  const translate = useTranslate();        
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const [formData, setFormData] = useState(props.record); // Estado local para almacenar los datos del formulario
    
    
  const onSuccess = ({ data }) => {
    notify(translate('common.notification.updated'));        
    redirect('/donations_projects');        
  };
  
  const onFailure = (error) => {
    let errorMessage = 'An error occurred';
    if (error instanceof HttpError) {
        errorMessage = error.message;
    }
    notify(errorMessage, 'error');
  };
  
  const dateParser = value => {      
    if (value == null || value === '') return '';        
    return toIsoString(new Date(value));
  }
        
  return (

    <Edit 
      title={<ElementTitle {...props}  />} 
      {...props} 
      id={id}
      mutationMode="pessimistic"
      actions={<MyActions {...props} />}
      onSuccess={onSuccess}  
      onFailure={onFailure}
    >
        
      <TabbedForm        
        toolbar={<FormToolbar />}
      >    
        <FormTab
          label={translate('classrooms.data')}
          contentClassName={classes.tab}
          path="" 
        > 
          <FormDataConsumer>
            {({ formData, ...rest }) => 
              <>                              
                <br/>
                <Grid container spacing={2}>
                    <Grid item xs={8}>   
                        <TextInput fullWidth source="name" label={translate('donations_projects.name')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} />
                    </Grid> 
                    <Grid item xs={4}>   
                        <TextInput fullWidth source="city" label={translate('donations_projects.city')} validate={[validateMaxLength(100,translate('common.validation.maxLength'))]} />
                    </Grid> 
                    <Grid item xs={3}>  
                        <BooleanInput fullWidth source="target_by_date" label={translate('donations_projects.target_by_date')} disabled={false} />
                    </Grid> 
                    { formData.target_by_date ?
                    <>
                    <Grid item xs={3}>   
                        <DateTimeInput fullWidth source="date_start" label={translate('donations_projects.date_start')} validate={[required()]} parse={dateParser} />
                    </Grid> 
                    <Grid item xs={3}>   
                        <DateTimeInput fullWidth source="date_finish" label={translate('donations_projects.date_finish')} validate={[required()]} parse={dateParser} />
                    </Grid>                     
                    <Grid item xs={3}>   
                        <NumberInput fullWidth source="amount" label={translate('donations_projects.amount')}  />
                    </Grid> 
                    </>
                    : 
                    <Grid item xs={9}></Grid>
                    }
                    
                    <Grid item xs={12}>   
                        <TextInput fullWidth source="description_short" label={translate('donations_projects.description_short')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />                    
                    </Grid> 
                    <Grid item xs={12}>   
                        <RichTextInput fullWidth source="description" label={translate('donations_projects.description')} />                    
                    </Grid> 
                    <Grid item xs={12}>   
                        <TextInput fullWidth source="video" label={translate('donations_projects.video')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />
                    </Grid> 
                    
                </Grid>    
                                
                <Typography variant="h6" className="grey8 titleUserShow">                        
                    {translate('donations_projects.payment_systems')}
                  </Typography>
                  <Divider fullWidth className="" />      
                  <br />
                    
                <Grid container spacing={2}>
                                                        
                    { subscriptionModule(modules.DONATIONS,modulesOptions.DONATIONS_FAC ) ?
                    <>
                    <Grid item xs={3}>  
                        <BooleanInput fullWidth source="payment_systems_fac" label={translate('donations_projects.payment_systems_fac')} disabled={true} />
                    </Grid> 
                    <Grid item xs={9}>   
                        <ReferenceInput sort={{ field: 'id', order: 'ASC' }} label={translate('donations_projects.ibans_iban')} source="ibans_id" reference="ibans" filter={{ associations_id: localStorage.getItem('associations_id') }} validate={[required()]} >
                            <SelectInput fullWidth optionText="name" optionValue="id" resettable />
                        </ReferenceInput>                    
                    </Grid> 
                    </>
                    :
                    <>
                    <Grid item xs={3}>  
                        <BooleanInput fullWidth source="payment_card" label={translate('donations_projects.payment_card')} disabled={false} />
                    </Grid> 
                    { formData.payment_card ?
                    <Grid item xs={9}>   
                        <ReferenceInput sort={{ field: 'id', order: 'ASC' }} label={translate('donations_projects.payment_systems_id')} source="payment_systems_id" reference="payment_systems" validate={[required()]} filter={{ associations_id: localStorage.getItem('associations_id'),include_payment_system_fac : true  }}>
                            <SelectInput fullWidth optionText="name" optionValue="id" resettable />
                        </ReferenceInput>
                    </Grid> : null }
                    </>
                    }
                
                </Grid>    
                <Grid container spacing={2}>
                
                    { subscriptionModule(modules.DONATIONS,modulesOptions.DONATIONS_OWN ) ?
                    <>
                    <Grid item xs={3}>  
                        <BooleanInput fullWidth source="payment_transfer" label={ translate('donations_projects.payment_transfer') } disabled={false } />
                    </Grid> 
                    
                    { formData.payment_transfer ?
                    <Grid item xs={9}>   
                        <ReferenceInput sort={{ field: 'id', order: 'ASC' }} label={translate('donations_projects.payment_transfer_ibans_id')} source="payment_transfer_ibans_id" reference="ibans" filter={{ associations_id: localStorage.getItem('associations_id') }} validate={[required()]} >
                            <SelectInput fullWidth optionText="name" optionValue="id" resettable />
                        </ReferenceInput>      
                    </Grid> 
                    : null }
                    
                    </> : null }
                
                </Grid>
                
                { subscriptionModule(modules.DONATIONS,modulesOptions.DONATIONS_OWN ) ?
                <Grid container spacing={2}>
                    <Grid item xs={2}>   
                        <NumberInput fullWidth source="minimum_amount" label={translate('donations_projects.minimum_amount')} validate={[required(),validateDoubleGreatOrEqualThan(1,translate('common.validation.number'))]} disabled={ subscriptionModule(modules.DONATIONS,modulesOptions.DONATIONS_FAC ) ? true : false } />
                    </Grid> 
                </Grid> : null }
                
                <Typography variant="h6" className="grey8 titleUserShow">                        
                    {translate('donations_projects.contact_data')}
                  </Typography>
                  <Divider fullWidth className="" />      
                  <br />
                    
                <Grid container spacing={2}>                
                    <Grid item xs={4}>  
                        <TextInput fullWidth source="email" label={translate('donations_projects.email')} validate={[validateMaxLength(100,translate('common.validation.maxLength'))]} />
                    </Grid>
                    <Grid item xs={2}>  
                        <TextInput fullWidth source="phone" label={translate('donations_projects.phone')} validate={[validateMaxLength(50,translate('common.validation.maxLength'))]} />
                    </Grid>                
                </Grid>
                
                <Typography variant="h6" className="grey8 titleUserShow">                        
                  {translate('donations_projects.public')}
                </Typography>
                <Divider fullWidth className="" />      
                <br />
                
                <Grid container spacing={2}>
                    <Grid item xs={2} className="friendly-url-field text-right">       
                        {donationsUrl}/
                    </Grid>
                    <Grid item xs={4}>                                               
                        <TextInput fullWidth source="friendly_url" label={translate('donations_projects.friendly_url')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} />
                    </Grid>
                </Grid>
                
                <Grid container spacing={2}>
                    <Grid item xs={3}>   
                        <BooleanInput fullWidth source="public_entity" label={translate('donations_projects.public_entity')}  />
                    </Grid>                     
                    <Grid item xs={3}>   
                        <BooleanInput fullWidth source="public_fac" label={translate('donations_projects.public_fac')}  />
                    </Grid> 
                    <Grid item xs={3}>   
                        <BooleanInput fullWidth source="comments_actived" label={translate('donations_projects.comments_actived')}  />
                    </Grid> 
                    { !formData.target_by_date ?
                    <Grid item xs={3}>   
                        <BooleanInput fullWidth source="public_sum_donations" label={translate('donations_projects.public_sum_donations')} disabled={false} />
                    </Grid> : null }
                </Grid>   
                
                
              </>
            }
          </FormDataConsumer>

        </FormTab> 
        
        <FormTab
            label={translate('donations_projects.images')}
            contentClassName={classes.tab}
            path="images"                 
        > 
                      
            <br />
            <AccordionSection className="accordion" label={translate('donations_projects.images')} fullWidth defaultExpanded={true}>
              <DocsList target={targets.DONATION_PROJECT_IMAGE} items_id={id} />
            </AccordionSection>   

        </FormTab>
        
    
      </TabbedForm>
    </Edit>
  );
};

const ElementTitle = ({ record, ...props }) => {    
  const translate = useTranslate(); 
  return (<span>{translate('common.edit')} {record ? `"${record.name}"` : ''}</span>);
};

const FormToolbar = ({ id , ...props }) => {
    
  let redirect = '/donations_projects';
    
    
  return (
    <Toolbar {...props} className="user_form_toolbar" >
      <SaveButton />
      <DeleteButton redirect={redirect} mutationMode="pessimistic" />
    </Toolbar>
  );
};

const useStyles = makeStyles({
  comment: {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tab: {
    maxWidth: '100%',
    display: 'block',
  },
});



export const ValidateButton = ({ ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('donations_projects/' + localStorage.getItem('associations_id') + '/' + props.record.id + '/validate');
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  
  const dataProvider = useDataProvider();
  
  const dateParser = value => {      
    if (value == null || value === '') return '';        
    return toIsoString(new Date(value));
  }
          
  const handleClick = () => {        
    setShowDialog(true);        
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    create(            
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          refresh();
        },
        onFailure: ( error ) => {
          notify( error.message , 'error');
        }
      }
    );
  };
    
  return (
    <>            
      <Button onClick={handleClick} label={props.record.fac_validation_status == 1 ? translate('donations_projects.validate') : translate('donations_projects.edit')} className="">
        <DoneIcon />
      </Button>

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('donations_projects.edit')}
      >
        <DialogTitle>{translate('donations_projects.edit')}</DialogTitle>

        <FormWithRedirect
          save={handleSubmit}
          initialValues={{ associations_id : localStorage.getItem('associations_id'), donations_projects_id : props.record.id , fac_validation_status: props.record.fac_validation_status, legal_text_3: props.record.legal_text_3, priority: props.record.priority, public_fac: props.record.public_fac, public_fac_date : props.record.public_fac_date }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <DialogContent>
                <FormDataConsumer>
                {({ formData, ...rest }) => 
                <Grid container spacing={2}>
                  <Grid item xs={6}> 
                    <SelectInput fullWidth label={translate('donations_projects.fac_validation_status')} source="fac_validation_status" choices={choicesDonationsProjectsFacValidationStatus(translate)} validate={[required()]} />
                  </Grid> 
                  <Grid item xs={6}>                     
                  </Grid> 
                  { formData.fac_validation_status == 2 ?
                  <Grid item xs={12}>     
                    <TextInput fullWidth multiline source="legal_text_3" label={translate('donations_projects.legal_text_3')} validate={[required()]} />   
                  </Grid> : null }                  
                  <Grid item xs={4}> 
                    <BooleanInput fullWidth label={translate('donations_projects.public_fac')} source="public_fac" />
                  </Grid> 
                  { formData.public_fac ?
                  <>
                  <Grid item xs={5}> 
                    <DateTimeInput fullWidth source="public_fac_date" label={translate('donations_projects.public_fac_date')} parse={dateParser} />
                  </Grid> 
                  <Grid item xs={3}> 
                    <NumberInput fullWidth label={translate('common.priority')} source="priority" />
                  </Grid>                  
                  
                  </>
                  : null }
                </Grid>
                }
                </FormDataConsumer>
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};



export const ValidateRequestButton = ({ ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('donations_projects/' + localStorage.getItem('associations_id') + '/' + props.record.id + '/validate_request');
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  
  const dataProvider = useDataProvider();
          
  const handleClick = () => {        
    setShowDialog(true);        
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    create(            
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          refresh();
        },
        onFailure: ( error ) => {
          notify( error.message , 'error');
        }
      }
    );
  };
    
  return (
    <>            
      <Button onClick={handleClick} label={translate('donations_projects.validate_request')} className="">
        <DoneIcon />
      </Button>

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('donations_projects.validate_request')}
      >
        <DialogTitle>{translate('donations_projects.validate_request')}</DialogTitle>

        <FormWithRedirect
          save={handleSubmit}
          initialValues={{ associations_id : localStorage.getItem('associations_id'), donations_projects_id : props.record.id }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <DialogContent>                             
                <Grid container spacing={2}>
                  <Grid item xs={12}> 
                    {translate('donations_projects.validate_request_desc')}
                  </Grid>
                </Grid>
                <br/>
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                  label={translate('donations_projects.validate_request_button')}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};