import React, { useState } from 'react';

import {
  BooleanInput,
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  Edit,
  Create,
  SimpleForm,
  TextInput,
  BooleanField ,
  useTranslate,
  ResourceContextProvider, TopToolbar, CreateButton,NumberInput,useRedirect,useRefresh,SelectField,RadioButtonGroupInput,DateInput,required,
  FormDataConsumer, useNotify, SaveButton, Toolbar, FunctionField, useCreate, useDataProvider, Button, FormWithRedirect
} from 'react-admin';

import { Link } from 'react-router-dom';


import { parse } from 'query-string';

import Grid from '@mui/material/Grid';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import IconCancel from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Tooltip from '@mui/material/Tooltip';
import DoneIcon from '@mui/icons-material/Done';

import { makeStyles } from '@material-ui/core/styles';

import { GroupQuotePeriodList } from './groups_quotes_periods';


import { validateMaxLength, validateInteger  } from './../components/Validator';


export const GroupsQuotesList = ({ group_id, ...props }) => {
  const translate = useTranslate();  

  return (            
    <ResourceContextProvider value="groups_quotes" >

      <List              
        basePath="/groups_quotes" 
        filter={{ groups_id : group_id }}
        actions={<ListActions groups_id={ group_id } /> }
        empty={<ListActions groups_id={ group_id } /> }     
        label=""  
        title={<ElementTitleGroupsQuotes />}  
        bulkActionButtons={false} 
        pagination={false}
        perPage={1000}
        sort={{ field: 'priority', order: 'ASC' }}
      >
        <Datagrid>                    
          <TextField source="name" label={translate('common.name')} sortable={false} />  
          <SelectField source="type" choices={choicesTypeQuote} optionText="name" optionValue="id" label={translate('common.type')} sortable={false} />
          <FunctionField render={record => record.apply_to_all ? <BooleanField source="apply_to_all" /> : '' } label={translate('groups_quotes.apply_to_all_list')} sortable={false}  /> 
          <BooleanField source="status" label={translate('common.actived_female')} sortable={false}/>     
          <FunctionField render={record => record.is_donation ? <><BooleanField source="is_donation" /></> : '' } label={translate('groups_quotes.is_donation')} sortable={false}  /> 
          <FunctionField render={record => <>{record.is_donation ? <ApplyToRemittancesButton id={record.id} /> : null }</>  } label={translate('common.actions')} sortable={false}  /> 
          <EditButton groups_id={ group_id } />
        </Datagrid>                            
      </List>
    </ResourceContextProvider>
  );
};



export const ApplyToRemittancesButton = ({ id, ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('groups_quotes/' + id + '/apply-to-remittances' );
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  
  const dataProvider = useDataProvider();
  
  const today = new Date();
          
  const handleClick = () => {        
    setShowDialog(true);        
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    create(            
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          notify( translate('groups_quotes.apply_to_all_donation_ok') , 'success');
        },
        onFailure: ( error ) => {
          notify( translate('groups_quotes.apply_to_all_donation_ko') , 'error');
        }
      }
    );
  };
      
  return (
    <>        
      
        <Button onClick={handleClick} label={translate('groups_quotes.apply_to_all_donation')} title={translate('groups_quotes.apply_to_all_donation_title')} className="">
          <VolunteerActivismIcon  />
        </Button>

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('groups_quotes.apply_to_all_donation_title')}
      >
        <DialogTitle>{translate('groups_quotes.apply_to_all_donation_title')}</DialogTitle>

        <FormWithRedirect
          save={handleSubmit}
          initialValues={{ groups_quotes_id : id }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <DialogContent>
                {translate('groups_quotes.apply_to_all_donation_desc')}
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                  label={translate('common.yes')}
                  icon={<DoneIcon />}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};


const ListActions = ({groups_id, ...props}) => {
  return (
    <TopToolbar>
      <CreateCustomButton groups_id={groups_id} />
    </TopToolbar>
  );
};

const CreateCustomButton = ({groups_id, ...props}) => {

  return (
    <CreateButton
      {...props}
      component={Link}
      to={{
        pathname: '/groups_quotes/create',
        search: `?groups_id=${groups_id}`, 
      }}
    />
  );
};

/*
const Empty = () => {
    const { basePath, resource } = useListContext();
    return (
        <Box textAlign="center" m={1}>            
            <CreateButton basePath={basePath} />            
        </Box>
    );
};*/

/*
const ListActions = ( {groups_id, ...props} ) => {
    return (
    <TopToolbar>
        <CreateButton 
        component={Link}
        to={{
            pathname: '/groups_quotes/create',
            state: { record: { groups_id: groups_id } }
        }}/>
    </TopToolbar>
);
};*/

const choicesTypeQuote = [
  { id: 0, name: 'Periòdica' },
  { id: 1, name: 'Puntual' }
];

export const GroupsQuotesEdit = ({ id, ...props }) => {

  const translate = useTranslate();   

  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = ({ data }) => {
    notify(translate('common.notification.updated'));
    redirect(`/groups-tree/${data.groups_id}/quotes`);
    refresh();
  };

  return (
    <Edit 
      title={<ElementTitleEdit />} 
      {...props}  
      id={id}
      onSuccess={onSuccess}   
      mutationMode="pessimistic"
    > 
      <SimpleForm
        toolbar={<CustomToolbar {...props} />}
      >
        <Grid container spacing={12}>
          <Grid item xs={4}>
            <BooleanInput fullWidth source="status" label={translate('common.actived_female')} />                     
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={9}>
            <TextInput fullWidth source="name" label={translate('common.name')} validate={[required(),validateMaxLength(25,translate('common.validation.maxLength'))]} /> 
          </Grid>
                
          <Grid item xs={3}>
            <NumberInput fullWidth source="priority" step={1} label={translate('common.priority')} parse={value => value ? value : 0 } validate={[validateInteger(translate('common.validation.number'))]} />
          </Grid>
        </Grid>
            
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <BooleanInput fullWidth source="apply_to_all" label={translate('groups_quotes.apply_to_all')} />                     
          </Grid>
        </Grid>
        
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <BooleanInput fullWidth source="is_donation" label={translate('groups_quotes.is_donation')} />                     
          </Grid>
        </Grid>
            
        <RadioButtonGroupInput source="type" choices={choicesTypeQuote} optionText="name" optionValue="id" label={translate('common.type')} disabled />
        <FormDataConsumer>
          {({ formData, ...rest }) => formData.type==1 &&
                    <DateInput source="date_payment" label={translate('groups_quotes.date_payment')} {...rest} />
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => (formData.type==1 || formData.type==2 ) &&
                        <NumberInput source="quantity" step={1} label={translate('groups_quotes.quantity')} {...rest} validate={[validateInteger(translate('common.validation.number'))]} />
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => formData.type==0 &&
                    <GroupQuotePeriodList {...rest} />
          }
        </FormDataConsumer>
        <br />       

      </SimpleForm>

    </Edit>
  );

};

const customToolbarStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const CustomToolbar = props => {
    
  let strRedirect = `/groups-tree/${props.record.groups_id}/quotes`;
  return (
    <Toolbar {...props} classes={customToolbarStyles()}>
      <SaveButton />
      <DeleteButton undoable={false} redirect={strRedirect} />
    </Toolbar>
  );
};

export const GroupsQuotesCreate = props => {

  const translate = useTranslate();   
  const today = new Date().toISOString().slice(0, 10);
    
  const { groups_id: groups_id_string } = parse(props.location.search);
  const groups_id = groups_id_string ? parseInt(groups_id_string, 10) : '';

  return (
    <Create {...props} 
      title={<ElementTitleNew />} 
    >
      <SimpleForm
        redirect="edit"
        initialValues={{ type: 0, status: 1, priority: 1, date_payment: today, groups_id }}
      >
        <Grid container spacing={12}>
          <Grid item xs={2}>
            <BooleanInput fullWidth source="status" label={translate('common.actived_female')} />                     
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={9}>
            <TextInput fullWidth source="name" label={translate('common.name')} validate={[required(),validateMaxLength(25,translate('common.validation.maxLength'))]} /> 
          </Grid>
                
          <Grid item xs={3}>
            <NumberInput fullWidth source="priority" step={1} label={translate('common.priority')} parse={value => value ? value : 0 } validate={[validateInteger(translate('common.validation.number'))]} />
          </Grid>
        </Grid>
            
        <RadioButtonGroupInput source="type" choices={choicesTypeQuote} optionText="name" optionValue="id" label={translate('common.type')} validate={required()} />
        <FormDataConsumer>
          {({ formData, ...rest }) => formData.type==1 &&              
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <DateInput fullWidth source="date_payment" label={translate('groups_quotes.date_payment')} {...rest} /> 
                  </Grid>                    
                  <Grid item xs={3}>
                    <NumberInput fullWidth source="quantity" step={1} label={translate('groups_quotes.quantity')}  {...rest} validate={[validateInteger(translate('common.validation.number'))]} />
                  </Grid>
                </Grid>
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => ( formData.type==2 ) &&
                <NumberInput source="quantity" step={1} label={translate('groups_quotes.quantity')}  {...rest} validate={[validateInteger(translate('common.validation.number'))]} />                      
          }
        </FormDataConsumer>
            
            
      </SimpleForm>
    </Create>
  );

};

const ElementTitleEdit = ({ record }) => {
  const translate = useTranslate(); 
  console.log(record);
  return <span>{translate('common.edit')} {record ? '${record.name}' : ''}</span>;
};

const ElementTitleNew = () => {
  const translate = useTranslate(); 
  return <span>Afegir quota</span>;
};

const ElementTitleGroupsQuotes = ({ record }) => {
  const translate = useTranslate(); 
  return '';
};