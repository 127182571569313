import React, { useState } from 'react';

import {
  List, 
  Datagrid,
  TextField,
  DeleteButton,
  Edit,
  SelectInput,
  TextInput,
  Button ,
  Create,
  SimpleForm,
  CreateButton ,
  TopToolbar,
  FormTab,
  TabbedForm,
  ReferenceManyField,
  FormDataConsumer,
  useListContext, required,NumberInput,BooleanInput,SelectField,  FunctionField, ResourceContextProvider, useCreate, useRefresh, FormWithRedirect, Toolbar, SaveButton,
  useNotify,
  useRedirect,
  DateField  , 
  Filter, 
  downloadCSV,
  DateInput,
  SimpleFormIterator,
  ArrayInput,BooleanField,
  NumberField,
  ReferenceField,
  useDataProvider,
  RadioButtonGroupInput,
  useUpdate,
  DateTimeInput
} from 'react-admin';

import { useEffect } from 'react';

import jsonExport from 'jsonexport/dist';

import {useHistory} from 'react-router-dom';
import { useForm, useFormState } from "react-final-form";

import { AccordionSection  } from '@react-admin/ra-form-layout';


import { makeStyles } from '@material-ui/core/styles';

import Divider from '@mui/material/Divider';


import { useTranslate  } from '../common/language';

import Typography from '@mui/material/Typography';

import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import CloseIcon from '@mui/icons-material/Close';


import { green, orange, deepOrange, red } from '@mui/material/colors';

import RichTextInput from 'ra-input-rich-text';


import { TextFieldShow } from '../components/TextFieldShow';

import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import GavelIcon from '@material-ui/icons/Gavel';
import Save from '@material-ui/icons/Save';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Chip from '@material-ui/core/Chip';

import { ColorInput } from 'react-admin-color-input';

import { DocsList, DocsListShow } from './docs';
import { targets, modules, choicesClassroomsType, targetsEmails,emailsStatus, apiOrigin, apiDefaultLanguage, apiUrl, choicesTypeUser, choicesDonationsUsersPaymentMethod, choicesDonationsUsersPublicPaymentMethod, choicesDonationsUsersStatus } from './../common/constants';
import { dateOnlyToCatalan, subscriptionModule, setActivePermissionData, structArrayToIdList, DateTimeField, dateAndTimeToCatalan, DatagridNoResults } from './../common/utils';  

import { CityInput } from './../components/Inputs';
import { ExportButton } from './../components/ExportButton';
import { BackLink } from './../components/BackLink';

import { SendEmailIconStatus } from './../components/Emails';
import DownloadIcon from '@material-ui/icons/GetApp';

import { RenderBoolean, UserFullNameTxt } from '../components/User';
import Tooltip from '@mui/material/Tooltip';

import Box from '@material-ui/core/Box';

import { Link } from 'react-router-dom';

import { validateMaxLength, validateInteger, validateEmail, validateDoubleGreatZero, validateDouble } from './../components/Validator';


export const DonationsCertificatesList = (props) => {
  const translate = useTranslate();  
  const dataProvider = useDataProvider();
    
    
  let basePath='/donations_certificates'; 
  let resource = 'donations_certificates';
  let strRedirect = 'donations_certificates';
    
  let year = 0;
  if (props.match.params.year) year = props.match.params.year;
      
  return (
    <>       
    <ResourceContextProvider value={resource}>
      <List 
        basePath={basePath}   
        filter={{ associations_id: localStorage.getItem('associations_id'), year: year }}  
        actions={ <ListActions year={year} />}    
        bulkActionButtons={false}          
        perPage={25}           
        sort={{ field: 'firstName', order: 'ASC' }} 
        label=""  
        title=""  
        resource={resource}
        filters={ <MyFilter /> } 
        filterDefaultValues={ { q: "", only_certificate_not_sent: false }}
        
      >        
        <Datagrid   
          empty={ <DatagridNoResults /> } >            
          <TextField source="nif" label={translate('donations_users.nif')} sortable={false} />      
          <FunctionField label={translate('users.first_name')} render={record => <UserFullNameTxt record={record} /> } sortable={false} />          
          <TextField source="email" label={translate('donations_users.email')} sortable={false} />                  
          <NumberField source="total" label={translate('donations_users.amount')} sortable={false} locales="es-ES" options={{ style: 'currency', currency: 'EUR' }} /> 
                              
          <FunctionField label={translate('donations_users.certificate')} render={record => 
            <>
            <DownloadCertificate donations_certificates_id={record.id} />             
            <SendEmailIconStatus record={record} target={targetsEmails.DONATIONS_USERS_CERTIFICATE}  />                 
            </>
          } sortable={false}  />
                    
        </Datagrid>        
      </List>
    </ResourceContextProvider>
    </>
  );
};

export const DownloadCertificate = ({ record, donations_certificates_id, ...props}) => {
    
  const translate = useTranslate(); 
  const notify = useNotify();
    
    const handleAction = async () => {
        
        try {
        let headers = new Headers({
            'Authorization': localStorage.getItem('auth'),
            'Accept-Language': apiDefaultLanguage,
            'Role': localStorage.getItem('active_permission')
        });

        const result = await fetch(`${apiUrl}/donations_certificates/${donations_certificates_id}/download`, {
            headers: headers
        });

        if (!result.ok) {
            throw new Error(translate('donations_users.pdf_certificate_ko'));
        }

        const blob = await result.blob();
        const href = window.URL.createObjectURL(blob);

        const contentDisposition = result.headers.get('Content-Disposition');
        
        const fileName = contentDisposition
            ? contentDisposition.split('filename=')[1].split(';')[0].trim()
            : 'certificat.pdf'; // Nom per defecte

        const tempLink = document.createElement('a');
        tempLink.href = href;
        tempLink.setAttribute('download', fileName); 
        document.body.appendChild(tempLink);
        tempLink.click();

        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(href);
        
        notify( translate('donations_users.pdf_certificate_ko') , 'success');
        
    } catch (error) {
        
        notify( translate('donations_users.pdf_certificate_ko') , 'error');
    }
          
  };
  
  return (
    <Tooltip title={translate('donations_users.pdf_download')}> 
      <DownloadIcon onClick={handleAction} />
    </Tooltip>
  );

};


const MyFilter = (props) => {
    const translate = useTranslate(); 
    const history = useHistory();
        
    return (
    <Filter {...props}>         
        <TextInput label={translate('common.search')} source="q" alwaysOn validate={[validateMaxLength(50,translate('common.validation.maxLength'))]} resettable /> 
        <BooleanInput label={translate('donations_users.only_certificate_not_sent')} source="only_certificate_not_sent" alwaysOn />
    </Filter>
);
};

const exporter = (items, translate, notify) => {
    
  const itemsForExport = items.map(item => {
    var itemForExport = {
        id: item.id,
        year: item.year,            
        nif: item.nif,        
        first_name: item.first_name,
        last_name_1: item.last_name_1,
        last_name_2: item.last_name_2,
        email: item.email,
        amount: item.total != null ? item.total.toFixed(2).replace('.', ',') : '',   
        status: item.status == emailsStatus.SENT_TO_SMTP_OK ? 'Sí' : 'No',
        date_time: item.date_time
    };
    return itemForExport;
  });
  jsonExport(itemsForExport, {
    headers: ['id',
        'year',
        'nif',        
        'first_name',
        'last_name_1'
        ,'last_name_2'
        ,'email'
        ,'amount'
        ,'status'
        ,'date_time'
    ],
    rename: [translate('common.id'),
        translate('common.year'), 
        translate('users.nif'), 
        translate('users.first_name'),
        translate('users.last_name_1'),
        translate('users.last_name_2'),
        translate('users.email'),
        translate('donations_users.amount'),
        translate('donations_certificates.status'),
        translate('donations_certificates.date_time')
    ],        
        
  }, (err, csv) => {
    const csv_new = '\uFEFF' + csv;
    downloadCSV(csv_new, translate('donations_certificates.exporter_file_name') ); 
    notify(translate('common.download_ok'),"success");
  });
};


const ListActions = (props) => {
    const translate = useTranslate(); 
        
    let filterExport = { 
        associations_id: localStorage.getItem('associations_id'),    
        year: props.year,
        q: props.filterValues && props.filterValues.q ? props.filterValues.q : '',
        only_certificate_not_sent: props.filterValues && props.filterValues.only_certificate_not_sent ? props.filterValues.only_certificate_not_sent : false,
    };
    
    return (
    <TopToolbar>  
        <ExportButton maxResults="1000000" filterValues={filterExport} alwaysEnabled={false} exporter={exporter} sort={{ field: 'firstName', order: 'ASC' }} /> 
    </TopToolbar>
    );
};





