// in src/customRoutes.js
import * as React from 'react';
import { Route } from 'react-router-dom';
import { Settings, SettingsData, SettingsNotAvailable, SettingsAddMember,SettingsEmails,SettingsEmailsItem,SettingsSAdmin, SettingsClassroomRequests,SettingsAnnuaryForm, SettingsProfile, SettingsDonationsCertificates, SettingsDonationsOwn, SettingsDocs  } from './settings';
import { AddMember } from './components/AddMember';
import { SettingsIbans } from './components/Banks';
import { SettingsPaymentSystems } from './components/PaymentSystems';
import { SettingsPostalLabels } from './components/PostalLabels';
import { RecercatAddMember } from './components/RecercatAddMember';
import { RecercatValidateRequest } from './components/RecercatValidateRequest';
import { RecercatResumeCenter } from './components/RecercatResumeCenter';
import { ForgotPassword,ResetPassword } from './components/Password';
import { SignUp } from './components/SignUp';
import { default as Dashboard } from './components/Dashboard';
import { NotFound } from './components/NotFound';
import { ClassroomsRequests, ClassroomsRequestsItem } from './components/ClassroomsRequests';
import { Reports,ReportsAnnuary,ReportsResponsabilities,ReportsDonationsTransferValidations, ReportsDonationsProjects, ReportsDonationsPayments, ReportsDonationsCertificates } from './components/Reports';
import { PublicEventsList, PublicEvent } from './components/PublicEvents';
import { CheckMembers } from './components/CheckMembers';
import { AnnuaryForm } from './components/Annuary';
import { ModulesViewList } from './components/ModulesView';
import { DonationsUsersList } from './resources/donations_users';

export default [
  <Route exact path="/dashboard" component={Dashboard} />,
  <Route exact path="/not-found" component={NotFound} noLayout /> ,   
  <Route exact path="/settings" component={Settings} />,
  <Route exact path="/settings-data" component={SettingsData} />,
  <Route exact path="/settings-ibans" component={SettingsIbans} />,
  <Route exact path="/settings-payment-systems" component={SettingsPaymentSystems} />,
  <Route exact path="/settings-postal-labels" component={SettingsPostalLabels} />,
  <Route exact path="/settings-add-member" component={SettingsAddMember} />,    
  <Route exact path="/settings-classrooms-requests" component={SettingsClassroomRequests} />,
  <Route exact path="/settings-annuary-form" component={SettingsAnnuaryForm} />, 
  <Route exact path="/settings-donations-certificates" component={SettingsDonationsCertificates} />,
  <Route exact path="/settings-donations-own" component={SettingsDonationsOwn} />,
  <Route exact path="/settings-docs" component={SettingsDocs} />,
  <Route exact path="/settings-emails" component={SettingsEmails} />,
  <Route exact path="/settings-emails/:id" component={SettingsEmailsItem} />,
  <Route exact path="/settings-emails/:id/docs" component={SettingsEmailsItem} />,
  <Route exact path="/settings-users" component={SettingsNotAvailable} />,
  <Route exact path="/settings-subscription" component={SettingsNotAvailable} />,
  <Route exact path="/settings-profile" component={SettingsProfile} />,
  <Route exact path="/settings-sadmin" component={SettingsSAdmin} />,
  <Route path="/public/add-member" component={AddMember} noLayout/>,
  <Route path="/public/sol-licitud-carnet-recercat" component={RecercatAddMember} noLayout/>,
  <Route path="/public/validar-sol-licitud-carnet-recercat" component={RecercatValidateRequest} noLayout/>,
  <Route path="/public/resum-centre" component={RecercatResumeCenter} noLayout/>,
  <Route path="/public/classrooms-requests" component={ClassroomsRequests} noLayout/>,
  <Route path="/public/classrooms-requests-item" component={ClassroomsRequestsItem} noLayout/>,
  <Route exact path="/forgot-password" component={ForgotPassword} noLayout/>,
  <Route exact path="/reset-password" component={ResetPassword} noLayout/>,
  <Route exact path="/sign-up" component={SignUp} noLayout/>,
  <Route exact path="/reports" component={Reports} />,
  <Route exact path="/donations_users/:id" component={DonationsUsersList} />,
  <Route exact path="/reports/donations_certificates/:year" component={ReportsDonationsCertificates} />,
  <Route exact path="/reports/donations_projects" component={ReportsDonationsProjects} />,
  <Route exact path="/reports/donations_transfer_validations" component={ReportsDonationsTransferValidations} />,
  <Route exact path="/reports/donations_users/:id" component={DonationsUsersList} />,  
  <Route exact path="/reports/donations_payments/:date_from/:date_to" component={ReportsDonationsPayments} />,  
  <Route exact path="/reports/annuary" component={ReportsAnnuary} />,
  <Route exact path="/reports/responsabilities" component={ReportsResponsabilities} />,
  <Route exact path="/modules-view" component={ModulesViewList} />,
  <Route path="/public/events/list" component={PublicEventsList} noLayout/>,
  <Route path="/public/events" component={PublicEvent} noLayout/>,   
  <Route path="/public/annuary" component={AnnuaryForm} noLayout/>,
  <Route path="/public/check-members" component={CheckMembers} noLayout/>,  
    
];
