
import * as React from 'react';
import { Component } from 'react';
import customRoutes from './customRoutes';
import { Admin, Resource, LoginPage, Login, LoginForm } from 'react-admin';

import { Redirect } from 'react-router-dom';

import usersIcon from '@material-ui/icons/Group';
import modulesIcon from '@material-ui/icons/Apps';
import AssociationsIcon from '@material-ui/icons/Business';

/* RESOURCES */
import { AssociationsList,AssociationsEdit,AssociationsCreate  } from './resources/associations';
import { GroupsList, GroupsEdit  } from './resources/groups';
import { UsersList,UsersEdit,UsersCreate,UsersShow  } from './resources/users';
import { ModulesList,ModulesEdit,ModulesCreate  } from './resources/modules';
import { ModulesViewList } from './components/ModulesView';
import { GroupsQuotesEdit,GroupsQuotesCreate  } from './resources/groups_quotes';
import { RemittancesList,RemittancesEdit,RemittancesCreate  } from './resources/remittances';
import { RecercatCentersList, RecercatCentersCreate, RecercatCentersEdit } from './resources/recercat_centers';
import { DocsFacList } from './resources/docs_fac';
import { ClassroomsList,ClassroomsEdit  } from './resources/classrooms';
import { RealStateList,RealStateEdit, RealStateCreate, RealStateShow  } from './resources/real_state';
import { RealStateActivitiesList,RealStateActivitiesEdit, RealStateActivitiesCreate  } from './resources/real_state_activities';
import { RealStatePaymentsList,RealStatePaymentsEdit, RealStatePaymentsCreate  } from './resources/real_state_payments';
import { AgreementsList,AgreementsEdit, AgreementsCreate  } from './resources/agreements';
import { InsurancesList,InsurancesEdit, InsurancesCreate  } from './resources/insurances';
import { AlertsList, AlertsEdit, AlertsCreate  } from './resources/alerts';
import { EventsList  } from './resources/events';
import { DonationsProjectsList,DonationsProjectsEdit } from './resources/donations_projects';
import { DonationsUsersList } from './resources/donations_users';
import { EmailsHistoryList } from './resources/emails';
import { CustomFieldsList,CustomFieldsEdit, CustomFieldsCreate  } from './resources/custom_fields';
import { CustomFieldsOptionsList,CustomFieldsOptionsEdit, CustomFieldsOptionsCreate  } from './resources/custom_fields_options';

import { i18nProvider, useTranslate  } from './common/language';
import { dataProvider } from './common/dataProvider';

import { MyLayout } from './components/MyLayout';
import Dashboard from './components/Dashboard';

import { reducer as tree } from '@react-admin/ra-tree';

import authProvider from './common/authProvider';


import { connect } from 'react-redux';
import { userLogin } from 'react-admin';
import { MuiThemeProvider } from '@material-ui/core/styles';

const CustomLoginForm = (props) => {
    const translate = useTranslate();
    var domain = window.location.hostname.replaceAll('.','');
    return (
    <Login {...props}>
        <LoginForm/>
        { domain !== 'admingestioentitatscat' && domain !== 'localhost' ? null : 
        <div className="text-center">{translate('signup.link_or')} <a href="/#/sign-up">{translate('signup.link')}</a></div> }
    </Login>
)};

const NotFound = () => {
  return (<Redirect to="/not-found" />);
};

const App = () => {
  const translate = useTranslate();
  return (
    <Admin 
      title="Associacions" 
      i18nProvider={i18nProvider} 
      dashboard={Dashboard} 
      dataProvider={dataProvider} 
      disableTelemetry 
      customRoutes={customRoutes} 
      layout={MyLayout}    
      customReducers={{ tree }} 
      authProvider={authProvider}
      menu={false} 
      catchAll={NotFound}
      loginPage={CustomLoginForm}
    >   
      <Resource name="groups-tree" options={{ label: translate('main_menu.groups') }} list={GroupsList} show={GroupsEdit} />
      <Resource name="groups-tree-contacts" options={{ label: translate('main_menu.contacts') }} list={GroupsList} show={GroupsEdit} />
      <Resource name="users" options={{ label: translate('main_menu.users') }} list={UsersList} edit={UsersEdit} create={UsersCreate} icon={usersIcon} show={UsersShow} />
      <Resource name="users-fac-contacts" options={{ label: translate('main_menu.users') }} list={UsersList} edit={UsersEdit} create={UsersCreate} icon={usersIcon} show={UsersShow} />
      <Resource name="remittances" options={{ label: translate('main_menu.remittances') }} list={RemittancesList} edit={RemittancesEdit} create={RemittancesCreate} icon={usersIcon} /> 
      <Resource name="modules_view" />       
      <Resource name="associations" options={{ label: translate('main_menu.associations') }} list={AssociationsList} edit={AssociationsEdit} create={AssociationsCreate} icon={AssociationsIcon} />
      <Resource name="modules" options={{ label: translate('main_menu.modules') }} list={ModulesList} edit={ModulesEdit} create={ModulesCreate} icon={modulesIcon} />              
      <Resource name="subscriptions" options={{ label: translate('main_menu.subscriptions') }} />
      <Resource name="users_groups" options={{ label: translate('main_menu.users_groups') }} />  
      <Resource name="modules_options"  options={{ label: translate('main_menu.options') }} />  
      <Resource name="users_permissions"  options={{ label: translate('main_menu.permissions') }} />    
      <Resource name="responsabilities" />     
      <Resource name="users_groups_responsabilities" options={{ label: translate('users_groups.responsability'), name : 'càrrec' }} />  
      <Resource name="users_groups_history" />    
      <Resource name="users_groups_quotes" />   
      <Resource name="groups_quotes" edit={GroupsQuotesEdit} create={GroupsQuotesCreate} /> 
      <Resource name="groups_quotes_periods" options={{ label: translate('groups_quotes.title') }} />           
      <Resource name="remittances_users" />   
      <Resource name="remittances_users_items" />
      <Resource name="groups" />
      <Resource name="groups_with_quotes" />
      <Resource name="docs" />
      <Resource name="docs-contacts" />
      <Resource name="social_nets" options={{ label: translate('social_nets.title'),  name: translate('social_nets.title') } } />
      <Resource name="social_nets_contacts" options={{ label: translate('social_nets.title'),  name: translate('social_nets.title') } } />
      <Resource name="nets" />
      <Resource name="users_families" />
      <Resource name="remittances_users_returns" />
      <Resource name="users_contacts" />
      <Resource name="users_contacts_contacts" />
      <Resource name="users_economic_data" />
      <Resource name="users_demografy" />
      <Resource name="templates" />
      <Resource name="users_emails" />
      <Resource name="users_emails_contacts" />
      <Resource name="classrooms" options={{ label: translate('main_menu.classrooms') }} list={ClassroomsList} edit={ClassroomsEdit} icon={usersIcon} />        
      <Resource name="users_classrooms" options={{ label: translate('main_menu.classrooms') }} edit={ClassroomsEdit} icon={usersIcon} />        
      <Resource name="events" options={{ label: translate('main_menu.events') }} list={EventsList}  /> 
      <Resource name="activities" />
      <Resource name="labels" />
      <Resource name="bookings" />
      <Resource name="users_searcher" />
      <Resource name="census" />
      <Resource name="payers" />
      <Resource name="mailing_lists" />
      <Resource name="activities_users" />
      <Resource name="sections_users" />
      <Resource name="books" />
      <Resource name="users_addresses" />
      <Resource name="users_edited_books" />
      <Resource name="users_recognitions" />
      <Resource name="users_schedules" />
      <Resource name="users_sections" />
      <Resource name="users_shows" />
      <Resource name="users_activities" />
      <Resource name="users_login" />
      <Resource name="users_workers" />
      <Resource name="users_annuary" />
      <Resource name="users_trainings" />
      <Resource name="users_social_projects" />
      <Resource name="reports/annuary" />
      <Resource name="reports/responsabilities" />
      <Resource name="groups_cards" />
      <Resource name="real_state" options={{ label: translate('main_menu.real_state') }} list={RealStateList} edit={RealStateEdit} create={RealStateCreate} show={RealStateShow} icon={usersIcon} />
      <Resource name="real_state_activities" list={RealStateActivitiesList} edit={RealStateActivitiesEdit} create={RealStateActivitiesCreate} icon={usersIcon}/>
      <Resource name="real_state_payments" list={RealStatePaymentsList} edit={RealStatePaymentsEdit} create={RealStatePaymentsCreate} icon={usersIcon}/>
      <Resource name="agreements" options={{ label: translate('main_menu.agreements') }} list={AgreementsList} edit={AgreementsEdit} create={AgreementsCreate} icon={usersIcon} />
      <Resource name="alerts" list={AlertsList} edit={AlertsEdit} create={AlertsCreate} icon={usersIcon} />
      <Resource name="alerts_users_login" />      
      <Resource name="insurances" list={InsurancesList} edit={InsurancesEdit} create={InsurancesCreate} icon={usersIcon} />      
      <Resource name="ibans" />
      <Resource name="payment_systems" />  
      <Resource name="donations_projects" list={DonationsProjectsList} edit={DonationsProjectsEdit} icon={usersIcon} /> 
      <Resource name="donations_users" /> 
      <Resource name="donations_certificates" /> 
      <Resource name="donations_projects/payments" /> 
      <Resource name="postal_labels" />
      <Resource name="organizers" />
      <Resource name="recercat_centers" options={{ label: translate('main_menu.recercat_centers') }} list={RecercatCentersList} edit={RecercatCentersEdit} create={RecercatCentersCreate} icon={usersIcon}  />       
      <Resource name="recercat_centers/public/cities" />
      <Resource name="recercat_centers/public" />
      <Resource name="docs_fac" options={{ label: translate('main_menu.docs_fac') }} list={DocsFacList} icon={usersIcon}  /> 
      <Resource name="provinces" />
      <Resource name="regions" />
      <Resource name="cities" />
      <Resource name="emails" list={EmailsHistoryList} icon={usersIcon} /> 
      <Resource name="custom_fields" list={CustomFieldsList} edit={CustomFieldsEdit} create={CustomFieldsCreate} icon={usersIcon} /> 
      <Resource name="custom_fields_options" list={CustomFieldsOptionsList} edit={CustomFieldsOptionsEdit} create={CustomFieldsOptionsCreate} icon={usersIcon} />
    </Admin>);
};

export default App;
